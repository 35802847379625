var Handlebars = require("../../../../../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='social-networks-auth-component-item js-provider-item'>\n  <a class='js-provider-item-link' href='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"omniauthAuthorizeURL") : depth0), depth0))
    + "'>\n    <i class='social-networks-auth-component-item-icon "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "'></i>\n  </a>\n</div>\n";
},"useData":true});