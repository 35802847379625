var Handlebars = require("../../../../../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " auth-component-blocked";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div\n      class='auth-component-section auth-component-section-social-networks-auth-component'\n      data-divider-content=\""
    + container.escapeExpression(__default(require("../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"auth_component.desktop.divider_content",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":28},"end":{"line":10,"column":77}}}))
    + "\"\n    >\n"
    + ((stack1 = container.invokePartial(require("../../social-networks-auth-component/_container.handlebars"),depth0,{"name":"../../social-networks-auth-component/_container","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form \n  class='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"formClass") : depth0), depth0))
    + " auth-component js-auth-component "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"contentType") : depth0), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"lockUpdates") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":71},"end":{"line":2,"column":125}}})) != null ? stack1 : "")
    + "'\n  novalidate='true'\n>\n"
    + ((stack1 = container.invokePartial(require("./_body.handlebars"),depth0,{"name":"_body","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./_footer.handlebars"),depth0,{"name":"_footer","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"withSocialRegistration") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":2},"end":{"line":14,"column":9}}})) != null ? stack1 : "")
    + "</form>\n";
},"usePartial":true,"useData":true});