const PREPARE_USER_PROCESS_TYPE = {
    merge: 'merge',
    increase: 'increase',
  },
  RESULT_PROCESS_TYPE = {
    signIn: 'signIn',
    signUp: 'signUp',
  };

const getPrepareUserProcessType = () => {
  if (
    app.config.isUserSigned &&
    app.config.authComponent.currentProcessingUser
  ) {
    return PREPARE_USER_PROCESS_TYPE.merge;
  }

  if (app.config.isUserSigned) {
    return PREPARE_USER_PROCESS_TYPE.increase;
  }

  return null;
};

const getResultProcessType = () => {
  if (app.config.authComponent.currentProcessingUser) {
    return RESULT_PROCESS_TYPE.signIn;
  }

  if (app.config.isUserSigned) {
    return null;
  }

  return RESULT_PROCESS_TYPE.signUp;
};

export const getProcessDescriptionData = (user) => {
  return {
    prepareUserProcessType: getPrepareUserProcessType(),
    resultProcessType: getResultProcessType(),
    user: user || null,
  };
};
