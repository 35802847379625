import SELECTORS from '../const/selectors.js';

const userUtils = app.modules.userUtils;

const CLASS_NAMES = {
  loading: 'auth-component-loading',
  selfClearedFormGroup: 'form-group-self-cleared',
};

export const getLoadedApplicationUtils = () => {
  if (app.modules && app.modules.applicationUtils) {
    return app.modules.applicationUtils;
  }
  setTimeout(() => {
    getLoadedApplicationUtils();
  }, 300);
};

export const doesUserHaveNecessaryField = (fieldName, user) => {
  user = user || app.config.currentUser;

  return !!userUtils.getUserAttribute(fieldName, user);
};

export const getElement = (elementType, $currentComponent) => {
  return $currentComponent
    ? $currentComponent.find(namesToSelectorStr(elementType))
    : $();
};

export const getPlatform = () => (app.config.isMobile ? 'mobile' : 'desktop');

export const getCurrentGroup = ($field) =>
  $field.closest('[class*="js-group-"]');

const firstToUppercase = (string) =>
  string.charAt(0).toUpperCase() + string.substring(1);

export const typesToNames = (types, context, withoutState) => {
  context = context || 'field';

  return types
    .split(',')
    .map(function (type) {
      return (
        $.trim(type) +
        firstToUppercase(context) +
        (withoutState ? '' : ':not(:disabled)')
      );
    })
    .join(',');
};

export const typesToSelector = (types) =>
  namesToSelectorStr(typesToNames(types));

export const getOptionsByName = (name, $currentComponent) => {
  if (!$currentComponent) {
    return null;
  }

  return name.split('.').reduce(function (result, optionName) {
    return result[optionName];
  }, $currentComponent.data('options'));
};

export const optionalTypesToNames = ($currentComponent, name, context) => {
  return typesToNames(
    getOptionsByName('fields.' + name, $currentComponent).join(','),
    context || 'field'
  );
};

export const findCurrentComponent = ($parent) =>
  $parent.find(SELECTORS.component);

export const getCurrentComponent = (element) =>
  element.closest(SELECTORS.component);

const parseElementName = (elementName) => {
  const DIVIDER = ':',
    nameParts = elementName.split(DIVIDER);

  return {
    name: nameParts[0],
    state: nameParts
      .filter(function (item, index) {
        return index;
      })
      .map(function (item) {
        return DIVIDER + item;
      })
      .join(''),
  };
};

const nameToSelector = (elementName) => {
  const elementNameObj = parseElementName(elementName);

  return SELECTORS[elementNameObj.name] + elementNameObj.state;
};

export const namesToSelectorArr = (elementsNames) => {
  return elementsNames
    .split(',')
    .map((elementName) => nameToSelector($.trim(elementName)));
};

export const namesToSelectorStr = (elementsNames) =>
  namesToSelectorArr(elementsNames).join(',');

export const doOnResult = (onResult, $currentComponent, ...data) => {
  const resultOption = getOptionsByName(onResult, $currentComponent);
  if (!resultOption) {
    return;
  }
  resultOption(data);
};

export const toggleComponentLoadingIndicator = (
  display,
  $component,
  className
) => {
  if (getOptionsByName('withoutSpinner', $component)) {
    return;
  }

  $component.toggleClass(className || CLASS_NAMES.loading, !!display);
};

export const toggleGroupClassName = ($field, className) => {
  getCurrentGroup($field).toggleClass(className, !!$field.val());
};

export const toggleClearFieldIcon = ($field) => {
  toggleGroupClassName($field, CLASS_NAMES.selfClearedFormGroup);
};

export const getPasswordAutoFilledState = ($currentComponent) =>
  !getElement('passwordField', $currentComponent).val();

export const setValidationState = ($currentComponent, valid) => {
  $currentComponent.data({valid: !!valid}).toggleClass('invalid-auth', !valid);
};

export const createValidationError = (data) => {
  return {
    status: 422,
    message: data.message,
    errorAttribute: data.errorAttribute,
    name: 'ValidationError',
  };
};

export const typesToClassName = (types, context) => {
  return namesToSelectorArr(typesToNames(types, context, true))
    .map(function (selector) {
      return selector.replace(/\./, '');
    })
    .join(' ');
};

export const resetCaptchaAndClearTokenIfNeeded = (captchaName) => {
  if (!app.config[captchaName]) return;

  app.modules.smartCaptcha.reset();
  app.config[captchaName] = '';
};
