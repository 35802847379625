import {getOptionsByName, getElement} from './common-utils.js';

import {getAuthCodeErrorText} from './code-field-utils.js';

const parseToJSON = (data) => {
  try {
    return JSON.parse(data);
  } catch (error) {
    throw error;
  }
};

const extractBaseErrorMessage = (errors) => {
  if (!errors) {
    return;
  }

  return Array.isArray(errors)
    ? errors
        .filter((error) => error.base)
        .map((error) => error.base)
        .join('<br>')
    : errors.base;
};

const getBaseErrorMessage = (error) => {
  if (!error) {
    return;
  }

  if (typeof error === 'string') {
    error = parseToJSON(error);
  }

  return extractBaseErrorMessage(error.errors);
};

const showError = (error, $currentComponent) => {
  if (error.name === 'ValidationError') {
    showFieldMessage(
      getElement(error.errorAttribute + 'Group', $currentComponent),
      error.message
    );
    return;
  }

  const authCodeErrorText = getAuthCodeErrorText(error);
  if (authCodeErrorText) {
    showFieldMessage(
      getElement('codeGroup', $currentComponent),
      authCodeErrorText
    );
    return;
  }

  if (app.config.api.errorMessages[error.status]) {
    showFieldMessage(
      getElement('passwordGroup', $currentComponent),
      app.config.api.errorMessages[error.status]
    );
    return;
  }

  toggleFlashMessage(
    getBaseErrorMessage(error.responseText) ||
      getOptionsByName('error', $currentComponent) ||
      app.config.api.errorMessages.default,
    $currentComponent
  );
};

const getFlashMessageContainer = ($currentComponent) => {
  return getOptionsByName('selectors.flashMessage', $currentComponent)
    ? $(getOptionsByName('selectors.flashMessage', $currentComponent))
    : getElement('flashMessage', $currentComponent);
};

export const showFieldMessage = ($group, message, type) => {
  type = type || 'error';

  $group
    .addClass('with-' + type)
    .find('.js-' + type + '-message')
    .empty()
    .append($('<span>').text(message));
};

export const showErrors = (errors, $currentComponent) => {
  Array.isArray(errors)
    ? errors.forEach((error) => {
        showError(error, $currentComponent);
      })
    : showError(errors, $currentComponent);
};

export const removeMessages = ($group, $currentComponent) => {
  ($group || $currentComponent.find('[class*="js-group-"]:hidden'))
    .removeClass('with-succes, with-error')
    .find('.js-error-message, .js-success-message')
    .empty();
};

export const toggleFlashMessage = (message, isSuccess, $currentComponent) => {
  if (message) {
    getFlashMessageContainer($currentComponent)
      .show()
      .addClass(isSuccess ? 'with-success' : 'with-error')
      .empty()
      .append($('<span>').text(message));
  } else {
    getFlashMessageContainer($currentComponent)
      .hide()
      .removeClass('with-success, with-error')
      .empty();
  }
};
