import {
  getOptionsByName,
  doesUserHaveNecessaryField,
  getElement,
} from './common-utils.js';

import {toggleDisableState} from './form-state-utils.js';

import SELECTORS from '../const/selectors.js';

const getCodeLink = (data) => {
  const [$currentComponent, user, doesUserHaveEmail, action] = data;
  const doesUserHavePhone = user && user['use_phone_for_reset_password'];
  if (action) {
    $(SELECTORS.restorePasswordTextPrefix)
      .text(app.config.authComponent.data.restoreText.prefix[action])
      .removeClass('dn');
  }
  getElement('restorePasswordSmsAndEmailLink', $currentComponent).toggleClass(
    'dn',
    !user || !(doesUserHaveEmail && doesUserHavePhone)
  );

  $currentComponent
    .find(SELECTORS.restorePasswordLink + '[data-delivery-method="email"]')
    .toggleClass('dn', !user || !doesUserHaveEmail || doesUserHavePhone);

  $currentComponent
    .find(SELECTORS.restorePasswordLink + '[data-delivery-method="sms"]')
    .toggleClass('dn', !user || doesUserHaveEmail || !doesUserHavePhone);
};

export const getAuthCodeErrorText = (error) => {
  const response =
    error && error.responseText && $.parseJSON(error.responseText);
  return response && response.errors && response.errors['auth_code'];
};

export const toggleCodeFields = (
  $currentComponent,
  showPasswordField,
  hideAllFields
) => {
  hideAllFields = !!hideAllFields;

  getElement('passwordGroup', $currentComponent).toggleClass(
    'dn',
    !showPasswordField || hideAllFields
  );
  getElement('codeGroup', $currentComponent).toggleClass(
    'dn',
    showPasswordField || hideAllFields
  );
  toggleDisableState(
    $currentComponent,
    ['codeField', showPasswordField || hideAllFields],
    ['passwordField', !showPasswordField || hideAllFields]
  );
};

export const needToHideCodeFields = ($currentComponent) => {
  return (
    ((app.config.isUserSigned && !doesUserHaveNecessaryField('sid')) ||
      getOptionsByName('simpleRegistration', $currentComponent)) &&
    !app.config.authComponent.currentProcessingUser
  );
};

export const toggleGenerateCodeLinks = ($currentComponent, user, action) => {
  const doesUserHaveEmail = doesUserHaveNecessaryField('email', user);
  let $restoreLink, $restoreLinkEmail;

  if (app.config.authComponent.data.sendSmsWithPassword) {
    getCodeLink([$currentComponent, user, doesUserHaveEmail, action]);
  } else {
    $restoreLinkEmail = $currentComponent.find(
      SELECTORS.restorePasswordLink + '[data-delivery-method="email"]'
    );
    $restoreLink =
      ($restoreLinkEmail.length && $restoreLinkEmail) ||
      getElement('restorePasswordLink', $currentComponent);

    $restoreLink.toggleClass('dn', !user || !doesUserHaveEmail);
    getElement('sendCodeLink', $currentComponent).toggleClass(
      'dn',
      !user || doesUserHaveEmail
    );
  }
};
