import {
  getOptionsByName,
  getElement,
  resetCaptchaAndClearTokenIfNeeded,
} from './common-utils.js';

import {getComponentData} from './component-data-utils.js';

const userAPI = app.modules.userAPI,
  userUtils = app.modules.userUtils,
  REGISTRATION_TYPES = {
    simple: 'simple',
  };

const prepareDataToSignUp = (data, $currentComponent) => {
  if (getOptionsByName('simpleRegistration', $currentComponent)) {
    data.type = REGISTRATION_TYPES.simple;
  }

  return data;
};

const doCrossDomainAuthByCondition = () => $.Deferred().resolve();

export const registerUser = ($currentComponent, data) => {
  return userAPI
    .signUp(
      prepareDataToSignUp(
        getComponentData($currentComponent, data, true),
        $currentComponent
      )
    )
    .always(
      resetCaptchaAndClearTokenIfNeeded.bind(null, [
        'smartCaptchaTokenForAuthComponent',
      ])
    );
};

export const onAfterRegisterWithSuccess = (data, $$authUser) => {
  userUtils.setConfigData(data.user);

  doCrossDomainAuthByCondition()
    .done(function () {
      $$authUser && $$authUser.resolve();
    })
    .fail(function (error) {
      $$authUser && $$authUser.reject(error);
    });
};

export const authorizeUser = (userData) => {
  return userAPI
    .signIn(userData)
    .done(_onAfterAuthorizeUser)
    .always(
      resetCaptchaAndClearTokenIfNeeded.bind(null, [
        'smartCaptchaTokenForAuthComponent',
      ])
    );
};

function _onAfterAuthorizeUser() {
  doCrossDomainAuthByCondition(getOptionsByName('crossDomainAuth'));
}

export const isValueTruthy = (object, key) => !!object[key];

export const getUsersCode = ($currentComponent) => ({
  password: getElement('passwordField:not(:disabled)', $currentComponent).val(),
  auth_code: getElement('codeField:not(:disabled)', $currentComponent).val(),
});

export const saveMergeUserData = (data) => {
  IStorage.set('authComponent[mergeUserData]', JSON.stringify(data));
};

export const deleteProcessedUserData = () => {
  IStorage.set('authComponent[mergeUserData]', '');
};
