import {
  toggleComponentLoadingIndicator,
  doOnResult,
  findCurrentComponent,
  getElement,
  resetCaptchaAndClearTokenIfNeeded,
} from '../utils/common-utils.js';

import {
  showErrors,
  removeMessages,
  toggleFlashMessage,
} from '../utils/errors-utils.js';

import {
  getAuthCodeErrorText,
  toggleCodeFields,
  needToHideCodeFields,
  toggleGenerateCodeLinks,
} from '../utils/code-field-utils.js';

import {validateComponent} from '../utils/component-state-utils.js';
import {getProcessDescriptionData} from '../utils/process-description-data-utils.js';

import {
  toggleDisableState,
  getFieldsToCheckFormat,
} from '../utils/form-state-utils.js';

import processFieldData from './process-field-data.js';

import {
  registerOrAuthorizeUser,
  increaseAuthorizationLevel,
  afterIncreaseAuthorizationLevel,
  afterMergedUserData,
} from './auth-component-user-action.js';

import ON_EVENT from '../const/on-event.js';

let _$currentComponent, _$store;

const userUtils = app.modules.userUtils;

const _onAfterValidateComponentWithSuccess = ($currentComponent) => {
  if (app.config.isUserSigned) {
    if (app.config.authComponent.currentProcessingUser) {
      // ids can be equal in case when user:
      // 1. Open several browser tabs with auth component
      // 2. Increase auth level in first tab.
      // 3. Try to authorize with the same data(from the first tab) in the second tab.
      return app.config.authComponent.currentProcessingUser.id !==
        userUtils.getUserAttribute('id')
        ? increaseAuthorizationLevel(
            $currentComponent,
            app.config.authComponent.currentProcessingUser
          )
            .then(afterIncreaseAuthorizationLevel)
            .then(afterMergedUserData)
        : $.Deferred().resolve({user: currentProcessingUser});
    } else {
      return increaseAuthorizationLevel(
        $currentComponent,
        app.config.authComponent.currentProcessingUser
      );
    }
  }
  return registerOrAuthorizeUser(
    !!app.config.authComponent.currentProcessingUser,
    $currentComponent
  );
};

const _onProcessUserDataDone = ($$processUser, data) => {
  userUtils.setConfigData(data.user);
  toggleFlashMessage(_$currentComponent);
  _$currentComponent.parent().addClass('processed-with-success');
  _$currentComponent.off();
  toggleComponentLoadingIndicator(false, _$currentComponent);
  doOnResult(
    ON_EVENT.completed,
    _$currentComponent,
    getProcessDescriptionData(data.user)
  );
  document.dispatchEvent(new Event('authEvent:valid'));
  $$processUser && $$processUser.resolve();
};

function _onProcessUserDataFail($$processUser, errors) {
  removeMessages(
    getElement('passwordGroup, codeGroup', _$currentComponent),
    _$currentComponent
  );
  showErrors(errors, _$currentComponent);
  resetCaptchaAndClearTokenIfNeeded('smartCaptchaTokenForAuthComponent');

  if (getAuthCodeErrorText(errors)) {
    getElement('codeField', _$currentComponent).val('');
  } else {
    toggleCodeFields(
      _$currentComponent,
      true,
      needToHideCodeFields(_$currentComponent)
    );
    toggleGenerateCodeLinks(
      _$currentComponent,
      app.config.authComponent.currentProcessingUser
    );
  }
  toggleDisableState(_$currentComponent, ['submitButton', false]);
  toggleComponentLoadingIndicator(false, _$currentComponent);
  doOnResult(ON_EVENT.error, _$currentComponent, errors);
  document.dispatchEvent(new Event('authEvent:errorValid'));
  $$processUser && $$processUser.reject(errors);
}

const _needToValidateField = ($field, $currentComponent) => {
  return getFieldsToCheckFormat($currentComponent).is($field);
};

const _processLastActiveFieldChanges = (lastActiveField) => {
  return lastActiveField &&
    _needToValidateField(lastActiveField, _$currentComponent)
    ? processFieldData(lastActiveField, _$currentComponent)
    : $.Deferred().resolve();
};

const _processUserData = () => {
  doOnResult(ON_EVENT.processUserData, _$currentComponent);

  return validateComponent(
    {
      invalid: 'toCheckFormat',
      required: 'required',
    },
    _$currentComponent
  ).then(() => _onAfterValidateComponentWithSuccess(_$currentComponent));
};

export const runAuthorizationCycle = (
  $$processUser,
  $currentComponent,
  $store
) => {
  _$currentComponent = $currentComponent;
  _$store = $store;
  toggleComponentLoadingIndicator(true, $currentComponent);

  _processLastActiveFieldChanges(
    $currentComponent.data('lastActiveField')
  ).always(function () {
    _processUserData()
      .done(_onProcessUserDataDone.bind(null, $$processUser))
      .fail(_onProcessUserDataFail.bind(null, $$processUser));
  });
};

export function onProcessUserData($container, $$processUser, $store) {
  runAuthorizationCycle(
    $$processUser,
    findCurrentComponent($container),
    $store
  );
}
