export default {
  component: '.js-auth-component',

  userField: '.js-input-user-auth',
  nameField: '.js-input-name-auth',
  emailField: '.js-input-email-auth',
  phoneField: '.js-input-phone-auth',
  passwordField: '.js-input-password-auth',
  codeField: '.js-input-code-auth',
  multipleField: '.js-input-multiple-auth',

  nameGroup: '.js-group-name-auth',
  phoneGroup: '.js-group-phone-auth',
  emailGroup: '.js-group-email-auth',
  codeGroup: '.js-group-code-auth',
  passwordGroup: '.js-group-password-auth',
  multipleGroup: '.js-group-multiple-auth',

  restorePasswordLink: '.js-restore-password-auth',
  restorePasswordSmsAndEmailLink: '.js-restore-password-auth-sms-and-email',
  restorePasswordSmsLink: '.js-restore-password-auth-sms',
  restorePasswordEmailLink: '.js-restore-password-auth-email',
  sendCodeLink: '.js-get-code-auth',
  restorePasswordTextPrefix: '.js-restore-text-prefix',

  existingUsersContainer: '.js-existing-users-container',
  userExistRadio: '.js-user-exist-radio-auth',
  userExistsButton: '.js-button-yes-users-exists',
  userDoesntExistButton: '.js-button-no-users-exists',

  submitButton: '.js-button-submit',

  flashMessage: '.js-flash-message',

  clearFieldIcon: '.js-icon-clear-field',
  togglePasswordIcon: '.js-icon-toggle-password',
};
