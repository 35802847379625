import {
  getOptionsByName,
  getCurrentGroup,
  optionalTypesToNames,
  getElement,
  createValidationError,
} from './utils/common-utils.js';

import {setComponentState} from './utils/component-state-utils.js';
import {resetFormState} from './utils/form-state-utils.js';
import {needToValidateWithUserSearching} from './utils/search-user-utils.js';

import {removeMessages, showErrors} from './utils/errors-utils.js';

import mutateMultipleField from './blocks/multiple-field.js';

import SELECTORS from './const/selectors.js';

const isFieldFilled = ($field) => $field.val().length;

const toggleFieldValidationState = ($field, $currentComponent, isValid) => {
  const $currentGroup = getCurrentGroup($field),
    data = $currentGroup.data({valid: !!isValid}).data(),
    experimentalFieldClass = getOptionsByName(
      'experimentalFieldClass',
      $currentComponent
    );
  let toogleExperimentalFieldClass;

  !isValid
    ? showErrors(
        createValidationError({
          errorAttribute: data.type,
          message: data.invalidDataMessage,
        }),
        $currentComponent
      )
    : removeMessages($currentGroup, $currentComponent);

  if (experimentalFieldClass) {
    toogleExperimentalFieldClass = !!isValid && !!isFieldFilled($field);
    $field
      .toggleClass(experimentalFieldClass, toogleExperimentalFieldClass)
      .parent()
      .toggleClass(
        experimentalFieldClass + '-wrapper',
        toogleExperimentalFieldClass
      );
  }
};

const setValidFieldValidationState = ($field, $currentComponent) => {
  toggleFieldValidationState($field, $currentComponent, true);
};

const validate = ($input) => {
  const value = $.trim($input.val()),
    $$isValid = $.Deferred();
  let dataType;

  if (!value) {
    return $$isValid.resolve($input);
  }

  dataType = getCurrentGroup($input).data('type');

  return app.modules.userValidationService
    .validate(
      value,
      dataType,
      dataType === 'phone' ? {type: 'mobile'} : undefined
    )
    .then(
      function (response) {
        return response.valid
          ? $$isValid.resolve($input)
          : $$isValid.reject($input);
      },
      function (response) {
        return $$isValid.reject($input);
      }
    );
};

const failValid = ($field, $currentComponent) => {
  toggleFieldValidationState($field, $currentComponent);
  setComponentState($currentComponent, null, function () {
    getElement(
      optionalTypesToNames($currentComponent, 'visibleIfDataCorrect', 'group'),
      $currentComponent
    ).addClass('dn');
  });
};

export const getFieldToValidate = ($field) => {
  return getCurrentGroup($field).is(SELECTORS.multipleGroup)
    ? mutateMultipleField($field)
    : $field;
};

export const validateFieldDataFormat = ($field, $currentComponent) => {
  if (needToValidateWithUserSearching($field)) {
    resetFormState($currentComponent);

    return validate(getFieldToValidate($field))
      .done(function () {
        setValidFieldValidationState($field, $currentComponent);
        document.dispatchEvent(new Event('authEvent:valid'));
      })
      .fail(() => {
        failValid($field, $currentComponent);
        document.dispatchEvent(new Event('authEvent:errorValid'));
      });
  }

  return validate($field)
    .done(() => {
      setValidFieldValidationState($field, $currentComponent);
    })
    .fail(() => {
      toggleFieldValidationState($field, $currentComponent);
    });
};

export const validateDataFormat = (date, $currentComponent) => {
  return $.when.apply(
    null,
    date.map((fieldName) => {
      const $field = getElement(fieldName, $currentComponent);

      return (
        $field.length &&
        isFieldFilled($field) &&
        validateFieldDataFormat($field, $currentComponent)
      );
    })
  );
};
