// Компонент авторизации/регистрации пользователя

import authComponentRender from '../../blocks/auth-component-render.js';

import {
  authComponentRegister,
  initLoadMergeUserData,
  loadMergeUserData,
} from '../../blocks/auth-component-user-action.js';

import {
  getLoadedApplicationUtils,
  findCurrentComponent,
} from '../../utils/common-utils.js';

import {onProcessUserData} from '../../blocks/process-user-data.js';

import SELECTORS from '../../const/selectors.js';

app.modules.authComponent = (function (self) {
  const ERROR_MESSAGES = {
    notAuthComponent: 'Argument should contain authorization component',
    notJQueryObject:
      'Argument should be present with jQuery object, instead got %s',
  };

  let _$currentComponent;

  function _setAndGetCurrentComponent($container) {
    _$currentComponent = findCurrentComponent($container);

    return _$currentComponent;
  }

  function _isComponentInitialized($component) {
    if ($component) {
      getLoadedApplicationUtils().invariant(
        $component instanceof jQuery,
        ERROR_MESSAGES.notJQueryObject,
        $component
      );

      getLoadedApplicationUtils().invariant(
        _isAuthComponent($component),
        ERROR_MESSAGES.notAuthComponent
      );
    }

    return !!($component || _setAndGetCurrentComponent($(this))).data(
      'initialized'
    );
  }

  function _isAuthComponent($element) {
    return $element.is(SELECTORS.component);
  }

  function _processUserData($$processUser) {
    onProcessUserData($(this), $$processUser);
  }

  function _commonListener() {
    $doc
      .on('render:authComponent', authComponentRender)
      .on('register:authComponent', authComponentRegister);
  }

  $.extend(self, {
    load: function () {
      //проверяем уровень авторизации и ничего не делаем, если он максимальный
      if (
        !(
          Object.keys(app.config.currentUser).length &&
          app.config.currentUser.email
        )
      ) {
        initLoadMergeUserData();
      }
      _commonListener();
    },
    isInitialized: _isComponentInitialized,
    processUserData: _processUserData,
    reset: function () {
      app.config.authComponent.currentProcessingUser = null;
      _$currentComponent = null;
    },
    loadMergeUserData: loadMergeUserData,
    onRenderComponent: authComponentRender,
    onRegister: authComponentRegister,
  });

  return self;
})(app.modules.authComponent || {});
