import {
  getCurrentGroup,
  typesToClassName,
  getElement,
} from '../utils/common-utils.js';

const MULTIPLE_FIELD_TYPES = ['phone', 'email'];

const getMultipleFieldProps = (dataType) => {
  let fieldType = dataType || MULTIPLE_FIELD_TYPES.join(',');

  return {
    fieldClassName: typesToClassName(fieldType),
    groupClassName: typesToClassName(fieldType, 'group'),
  };
};

const getMultipleFieldDataType = ($field) => {
  return /@/.test($field.val())
    ? MULTIPLE_FIELD_TYPES[1]
    : MULTIPLE_FIELD_TYPES[0];
};

const resetMultipleFieldProps = ($field) => {
  const fieldProps = getMultipleFieldProps();

  getCurrentGroup($field.removeClass(fieldProps.fieldClassName))
    .data({type: 'multiple'})
    .removeClass(fieldProps.groupClassName);
};

const setMultipleFieldType = ($field, type) => {
  getCurrentGroup($field).data({type: type});
};

const setMultipleFieldName = ($field, name) => {
  $field.attr({name: name});
};

const setMultipleFieldClassNames = ($field, type) => {
  const multipleFieldProps = getMultipleFieldProps(type);

  getCurrentGroup($field.addClass(multipleFieldProps.fieldClassName)).addClass(
    multipleFieldProps.groupClassName
  );
};

const setMultipleFieldProps = ($field) => {
  let type = getMultipleFieldDataType($field);

  setMultipleFieldType($field, type);
  setMultipleFieldName($field, type);
  setMultipleFieldClassNames($field, type);
};

export default (field, $currentComponent) => {
  let $field = field || getElement('multipleField', $currentComponent);

  resetMultipleFieldProps($field);
  setMultipleFieldProps($field);

  return $field;
};
