import {
  getLoadedApplicationUtils,
  getOptionsByName,
} from '../utils/common-utils.js';

const ACTIONS = {
    reload: 'reload',
    redirect: 'redirect',
  },
  EVENTS = {
    signIn: 'signIn',
    signUp: 'signUp',
    act: 'act',
  };

const getActionOnSuccessParams = (data) => {
  return Object.keys(data).reduce(function (result, currentItem) {
    const params = data[currentItem].split('=');

    result[currentItem] = {
      name: $.trim(params[0]),
      url: $.trim(params[1]) || null,
    };
    return result;
  }, {});
};

const prepareActionOnSuccessData = (data) =>
  typeof data === 'string' ? {act: data} : data;

const getActionOnSuccessEventName = (data) => {
  return typeof data === 'string'
    ? EVENTS.act
    : app.config.authComponent.currentProcessingUser
    ? EVENTS.signIn
    : EVENTS.signUp;
};

const doActionOnSuccess = (data) => {
  const actionParams = getActionOnSuccessParams(
      prepareActionOnSuccessData(data)
    ),
    eventName = getActionOnSuccessEventName(data);

  switch (actionParams[eventName].name) {
    case ACTIONS.reload:
      return location.reload(true);
    case ACTIONS.redirect:
      return getLoadedApplicationUtils().setLocationHref(
        actionParams[eventName].url
      );
  }
};

export default ($currentComponent) => {
  const actionOnSuccessData = getOptionsByName(
    'actionOnSuccess',
    $currentComponent
  );

  actionOnSuccessData && doActionOnSuccess(actionOnSuccessData);
};
