import {
  onProcessingDataFieldEvent,
  disableFieldSynchronization,
  onInputWithTarget,
  writeDataInStore,
  onGenerateCode,
  userDoesntExist,
  userExists,
  onSubmit,
  onClearField,
  onToggleClearIcon,
  onTogglePassword,
  onToggleIconToTogglePassword,
  onSynchronizeFields,
  onCloseComponent,
  onUnmount,
} from './form-action.js';

import {
  optionalTypesToNames,
  namesToSelectorStr,
  getOptionsByName,
  typesToSelector,
} from '../utils/common-utils.js';

import {toggleDisableState} from '../utils/form-state-utils.js';

import {onProcessUserData} from './process-user-data.js';

import SELECTORS from '../const/selectors.js';

const TOGGLE_ICONS_EVENTS = ['input', 'blur'];

const _optionalTypesToSelector = ($currentComponent, groupType, context) => {
  return namesToSelectorStr(
    optionalTypesToNames($currentComponent, groupType, context)
  );
};

const _getComponentFieldsSelector = () => {
  return namesToSelectorStr(
    'userField, nameField, emailField, phoneField, passwordField, codeField'
  );
};

const _dispatchAddTotalSmartCaptcha = () => {
  document.dispatchEvent(new Event('addTotalCaptcha'));
};

export default ($currentComponent, $container, $store) => {
  const needCaptchaOnPage = !!app.config.yandexCaptchaKey;

  if (needCaptchaOnPage) {
    window.smartCaptcha
      ? _dispatchAddTotalSmartCaptcha()
      : document.addEventListener(
          'smartCapcha:loaded',
          _dispatchAddTotalSmartCaptcha,
          {once: true}
        );
  }

  $currentComponent
    .on(
      'input',
      _optionalTypesToSelector($currentComponent, 'toCheckFormat'),
      function (event) {
        onProcessingDataFieldEvent(event, $(this), $currentComponent);
      }
    )
    .on(
      'blur',
      _optionalTypesToSelector($currentComponent, 'toSynchronize'),
      disableFieldSynchronization
    )
    .on(
      'input',
      _optionalTypesToSelector($currentComponent, 'set'),
      onInputWithTarget
    )
    .on('input', _getComponentFieldsSelector(), function () {
      writeDataInStore($store, $currentComponent);
    })
    .on(
      'click',
      namesToSelectorStr(
        'sendCodeLink, restorePasswordLink, restorePasswordSmsLink, restorePasswordEmailLink'
      ),
      function (event) {
        if (event.target.closest(SELECTORS.sendCodeLink)) {
          needCaptchaOnPage
            ? app.modules.smartCaptcha.check('smartCaptchaTokenForSendCodeLink')
            : onGenerateCode($(this), $currentComponent);
          return;
        }
        onGenerateCode($(this), $currentComponent);
      }
    )

    .on('change', SELECTORS.userExistRadio, function () {
      toggleDisableState($currentComponent, ['userExistsButton', false]);
    })
    .on('click', SELECTORS.userDoesntExistButton, function () {
      userDoesntExist($currentComponent);
    })
    .on('click', SELECTORS.userExistsButton, function () {
      userExists($currentComponent);
    })
    .on('submit', function (event) {
      event.preventDefault();

      if (getOptionsByName('disableSmartCaptcha', $currentComponent)) {
        onSubmit($container, $currentComponent, $store);
      } else {
        needCaptchaOnPage
          ? app.modules.smartCaptcha.check('smartCaptchaTokenForAuthComponent')
          : onSubmit($container, $currentComponent, $store);
      }
    });

  if (needCaptchaOnPage) {
    document.addEventListener('smartCaptchaTokenForAuthComponent', (event) => {
      app.config.smartCaptchaTokenForAuthComponent = event.detail;
      onSubmit($container, $currentComponent, $store);
    });

    document.addEventListener('smartCaptchaTokenForSendCodeLink', (event) => {
      app.config.smartCaptchaTokenForSendCodeLink = event.detail;
      onGenerateCode($(SELECTORS.sendCodeLink), $currentComponent);
    });
  }

  if (getOptionsByName('fields.selfCleared', $currentComponent)) {
    $currentComponent
      .on('click', SELECTORS.clearFieldIcon, onClearField)
      .on(
        TOGGLE_ICONS_EVENTS.join(' '),
        _optionalTypesToSelector($currentComponent, 'selfCleared'),
        onToggleClearIcon
      );
  }

  if (getOptionsByName('withToggledPassword', $currentComponent)) {
    $currentComponent
      .on('click', SELECTORS.togglePasswordIcon, onTogglePassword)
      .on(
        TOGGLE_ICONS_EVENTS.join(' '),
        SELECTORS.passwordField,
        onToggleIconToTogglePassword
      );
  }

  $container
    .on('processUserData:authComponent', function (event, data) {
      onProcessUserData($container, data, $store);
    })
    .on('synchronizeFields:authComponent', function (event, $fields) {
      onSynchronizeFields($fields, $store, $currentComponent);
    })
    .on(
      'subscribeOnFieldValue:authComponent',
      function (event, fields, callback) {
        $currentComponent.on('blur', typesToSelector(fields), callback);
      }
    )
    .on(
      'unsubscribeFromFieldValue:authComponent',
      function (event, fields, callback) {
        $currentComponent.off('blur', typesToSelector(fields), callback);
      }
    )
    .on('close:authComponent', function () {
      onCloseComponent($currentComponent);
    });

  $store
    .on('processUserData:authComponent', function (event, data) {
      onProcessUserData($container, data, $store);
    })
    .on('synchronizeFields:authComponent', function (event, $fields) {
      onSynchronizeFields($fields, $store, $container);
    })
    .on('unmount:authComponent', onUnmount.bind($store, $container));
};
