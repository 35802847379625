var Handlebars = require("../../../../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div \n  class='social-networks-auth-component-container loading js-social-networks-auth-component-container'\n  data-providers-vkontakte-locales-text=\""
    + alias2(__default(require("../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"auth_component.oauth.vkontakte.text",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":41},"end":{"line":3,"column":87}}}))
    + "\"\n  data-providers-vkontakte-locales-title=\""
    + alias2(__default(require("../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"auth_component.oauth.vkontakte.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":42},"end":{"line":4,"column":89}}}))
    + "\"\n  data-providers-odnoklassniki-locales-text=\""
    + alias2(__default(require("../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"auth_component.oauth.odnoklassniki.text",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":45},"end":{"line":5,"column":95}}}))
    + "\"\n  data-providers-odnoklassniki-locales-title=\""
    + alias2(__default(require("../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"auth_component.oauth.odnoklassniki.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":46},"end":{"line":6,"column":97}}}))
    + "\"\n  data-providers-facebook-locales-text=\""
    + alias2(__default(require("../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"auth_component.oauth.facebook.text",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":40},"end":{"line":7,"column":85}}}))
    + "\"\n  data-providers-facebook-locales-title=\""
    + alias2(__default(require("../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"auth_component.oauth.facebook.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":41},"end":{"line":8,"column":87}}}))
    + "\"\n></div>\n";
},"useData":true});