// Вспомогательные методы компонента авторизации/регистрации пользователя

import {getLoadedApplicationUtils} from '../../utils/common-utils.js';

app.modules.authComponentUtils = (function () {
  function _doesCurrentUserHaveData(dataType) {
    getLoadedApplicationUtils().invariant(
      typeof dataType === 'string' || Array.isArray(dataType),
      'Argument dataType should be a string or an array, instead got %s',
      dataType
    );

    getLoadedApplicationUtils().invariant(
      app.config.currentUser,
      'currentUser config should be declared'
    );

    return Array.isArray(dataType)
      ? dataType.reduce(function (result, currentType) {
          return result && !!app.config.currentUser[currentType];
        }, true)
      : !!app.config.currentUser[dataType];
  }

  return {
    doesCurrentUserHaveData: _doesCurrentUserHaveData,
  };
})(app.modules.authComponentUtils || {});
