var Handlebars = require("../../../../../../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "inline";
},"3":function(container,depth0,helpers,partials,data) {
    return "dn";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class='label'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"customFormSettings") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":6},"end":{"line":12,"column":13}}})) != null ? stack1 : "")
    + "      <label class='required' style='"
    + alias2(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customFormSettings") : depth0)) != null ? lookupProperty(stack1,"styles") : stack1)) != null ? lookupProperty(stack1,"labelStyles") : stack1), depth0))
    + "'>\n        "
    + alias2(__default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"auth_component.desktop.code.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":14,"column":52}}}))
    + "\n      </label>\n    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class='required-icon' style='"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customFormSettings") : depth0)) != null ? lookupProperty(stack1,"styles") : stack1)) != null ? lookupProperty(stack1,"requireStyles") : stack1), depth0))
    + "'>*</span>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "custom-text-field";
},"10":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"auth_component.desktop.code.placeholder",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":25,"column":61},"end":{"line":25,"column":111}}}));
},"12":function(container,depth0,helpers,partials,data) {
    return "    <span class='password-link dn js-restore-password-auth' data-delivery-method='email'>\n      "
    + container.escapeExpression(__default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"auth_component.desktop.password.link.restore_password_email",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":36,"column":6},"end":{"line":36,"column":76}}}))
    + "\n    </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div \n  class='form-group dn js-group-code-auth "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"fields") : depth0)) != null ? lookupProperty(stack1,"inline") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":42},"end":{"line":2,"column":81}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hiddenByDefault") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":82},"end":{"line":2,"column":119}}})) != null ? stack1 : "")
    + "'\n  data-required-data-message=\""
    + alias2(__default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"auth_component.desktop.code.message.required",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":30},"end":{"line":3,"column":85}}}))
    + "\"\n  data-success-message=\""
    + alias2(__default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"auth_component.desktop.code.message.success_request",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":24},"end":{"line":4,"column":86}}}))
    + "\"\n  data-bot-detected-message=\""
    + alias2(__default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"auth_component.desktop.code.message.bot_detected_message",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":29},"end":{"line":5,"column":96}}}))
    + "\"\n  data-type='code'\n>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"fields") : depth0)) != null ? lookupProperty(stack1,"withoutLabels") : stack1),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":2},"end":{"line":17,"column":13}}})) != null ? stack1 : "")
    + "  <div class='text-field-wrapper'>\n"
    + ((stack1 = container.invokePartial(require("../_icons-holder.handlebars"),depth0,{"name":"../_icons-holder","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <input \n      class='text-field js-input-code-auth "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCustomform") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":43},"end":{"line":21,"column":92}}})) != null ? stack1 : "")
    + "'\n      style='"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customFormSettings") : depth0)) != null ? lookupProperty(stack1,"styles") : stack1)) != null ? lookupProperty(stack1,"inputStyles") : stack1), depth0))
    + "'\n      type='text'\n      name='code'\n      placeholder=\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"fields") : depth0)) != null ? lookupProperty(stack1,"withoutPlaceholder") : stack1),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":19},"end":{"line":25,"column":122}}})) != null ? stack1 : "")
    + "\"\n      disabled='true'\n      value='"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fields") : depth0)) != null ? lookupProperty(stack1,"values") : stack1)) != null ? lookupProperty(stack1,"auth_code") : stack1), depth0))
    + "'\n      autocomplete='"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"fields") : depth0)) != null ? lookupProperty(stack1,"autocomplete") : stack1), depth0))
    + "'\n    />\n  </div>\n  <div class='success-block js-success-message'></div>\n  <div class='error-block js-error-message'></div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"sendSmsWithPassword") : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":2},"end":{"line":38,"column":9}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});