import {getOptionsByName} from '../utils/common-utils.js';

const CONTENT_TYPES = {
  signIn: 'sign-in',
  signUp: 'sign-up',
  common: 'common',
};

const userUtils = app.modules.userUtils;

const getAllContentTypes = () => {
  return Object.keys(CONTENT_TYPES)
    .map((key) => {
      return CONTENT_TYPES[key];
    })
    .join(' ');
};

const setContentType = (contentType, $currentComponent) => {
  $currentComponent.removeClass(getAllContentTypes()).addClass(contentType);
};

const getContentType = (isContentToSignIn) =>
  isContentToSignIn ? CONTENT_TYPES.signIn : CONTENT_TYPES.signUp;

const isContentToSignIn = (isUserFound) =>
  isUserFound || (app.config.isUserSigned && !userUtils.isSignedBySID());

export const setDefaultContentType = ($currentComponent) => {
  setContentType(
    getOptionsByName('contentType', $currentComponent),
    $currentComponent
  );
};

export const toggleContent = (isUserFound, $currentComponent) => {
  setContentType(
    getContentType(isContentToSignIn(isUserFound)),
    $currentComponent
  );
};
