import {getOptionsByName, getCurrentGroup} from './common-utils.js';

const FIELDS_TO_VALIDATE_WITHOUT_SEARCHING = ['name', 'password'];

const getRequestObject = (params) => {
  return $.extend(
    {
      beforeSend: function () {
        $doc.trigger('getProcess:authComponent');
      },
      complete: function () {
        $doc.trigger('getComplete:authComponent');
      },
    },
    params
  );
};

const prepareDataToSearchUser = (data, needToGetActiveUsers) => {
  return $.extend(
    Object.keys(data).reduce((result, currentKey) => {
      result[currentKey] = $.trim(data[currentKey]);

      return result;
    }, {}),
    {all: !needToGetActiveUsers}
  );
};

const api = (params) => {
  return $.ajax(getRequestObject(params));
};

export const needToValidateWithUserSearching = ($field) => {
  return !~FIELDS_TO_VALIDATE_WITHOUT_SEARCHING.indexOf(
    getCurrentGroup($field).data('type')
  );
};

export const isFieldRequired = (fieldType, $currentComponent) => {
  return ~getOptionsByName('fields.required', $currentComponent).indexOf(
    fieldType
  );
};

export const getByPhoneAndEmail = (phone, email) => {
  return api({
    url: app.config.api.usersURL,
    data: prepareDataToSearchUser({
      phone: phone,
      email: email,
    }),
  });
};

export const getByEmail = (email) => {
  return api({
    url: app.config.api.usersURL,
    data: prepareDataToSearchUser({email: email}),
  });
};

export const getByPhone = (phone) => {
  return api({
    url: app.config.api.usersURL,
    data: prepareDataToSearchUser({phone: phone}),
  });
};
