import {
  getLoadedApplicationUtils,
  getOptionsByName,
  getElement,
} from '../utils/common-utils.js';

import {
  registerUser,
  authorizeUser,
  onAfterRegisterWithSuccess,
  isValueTruthy,
  getUsersCode,
  saveMergeUserData,
  deleteProcessedUserData,
} from '../utils/user-action-utils.js';

import {getProcessDescriptionData} from '../utils/process-description-data-utils.js';
import {getComponentData} from '../utils/component-data-utils.js';

const userAPI = app.modules.userAPI,
  userUtils = app.modules.userUtils;

const _getDataToIncrease = ($currentComponent, currentProcessingUser) => {
  return getLoadedApplicationUtils().filterObject(
    $.extend(
      currentProcessingUser
        ? {
            id: currentProcessingUser.id,
          }
        : {
            id: userUtils.getUserAttribute('id'),
            email: getElement(
              'emailField:not(:disabled)',
              $currentComponent
            ).val(),
            phone: getElement(
              'phoneField:not(:disabled)',
              $currentComponent
            ).val(),
            name: getElement(
              'nameField:not(:disabled)',
              $currentComponent
            ).val(),
          },
      getUsersCode($currentComponent),
      getOptionsByName(
        'getAdditionalUserData',
        $currentComponent
      )(getProcessDescriptionData())
    ),
    isValueTruthy
  );
};

const _increaseAuthorizationListenProcess = (data) => {
  return getLoadedApplicationUtils()
    .listenProcess({
      url: location.protocol + '//' + location.host + data.url,
      pid: data.pid,
      rejectWith: 'mergeUsersJobFailed',
    })
    .fail(deleteProcessedUserData);
};

export const afterMergedUserData = (data) => {
  deleteProcessedUserData();

  return authorizeUser({perishable_token: data['perishable_token']}).then(
    function (response) {
      $doc.trigger('userMergeComplete:authComponent');
      return response;
    }
  );
};

export const loadMergeUserData = () => {
  const $$storage = $.Deferred();

  IStorage.get('authComponent[mergeUserData]', function (storage) {
    storage ? $$storage.resolve(JSON.parse(storage)) : $$storage.reject();
  });

  return $$storage;
};

export const initLoadMergeUserData = () => {
  loadMergeUserData()
    .then(_increaseAuthorizationListenProcess)
    .then(afterMergedUserData);
};

export const afterIncreaseAuthorizationLevel = (data) => {
  const url = document.createElement('a');
  let mergingData;

  url.href = data.url;
  mergingData = $.extend({}, data, {url: url.pathname});
  saveMergeUserData(mergingData);
  return _increaseAuthorizationListenProcess(mergingData);
};

export const increaseAuthorizationLevel = (
  $currentComponent,
  currentProcessingUser
) => {
  const dataToIncrease = _getDataToIncrease(
    $currentComponent,
    currentProcessingUser
  );

  !currentProcessingUser &&
    $doc.trigger('increaseRegistrationLevel:authComponent', [
      $currentComponent,
    ]);

  return currentProcessingUser
    ? userAPI.mergeUsers(dataToIncrease)
    : userAPI.updateUser(userUtils.getUserAttribute('id'), dataToIncrease);
};

export const registerOrAuthorizeUser = (isAuthorize, $currentComponent) => {
  return isAuthorize
    ? authorizeUser(getComponentData($currentComponent), $currentComponent)
    : registerUser($currentComponent);
};

export const authComponentRegister = (event, $$authUser, options) => {
  options = options || {};

  // For backwards compatibility
  registerUser(
    null,
    options.data ? $.extend({withSID: options.withSID}, options.data) : options
  )
    .done(function (data) {
      onAfterRegisterWithSuccess(data, $$authUser);
    })
    .fail($$authUser.reject);
};
