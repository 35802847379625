import {getLoadedApplicationUtils} from '../utils/common-utils.js';
import {modalDialog} from '@apress/apress-frontend-utils/utils/modal-dialog/modal-dialog.js';

const MAP_TO_DIALOG_OPTIONS = {
  onClose: 'close',
  onOpen: 'open',
  className: 'dialogClass',
  blockName: 'blockName',
};

let _$dialog;

const _closePopup = () => {
  _$dialog && _$dialog.close();
};

const _addPopupOpenedHistory = () => {
  window.history.pushState('popup-open', null, '');
  $win.on('popstate', _closePopup);
};

const _removePopupOpenedHistory = () => {
  if (window.history.state === 'popup-open') {
    window.history.back();
  }
  $win.off('popstate', _closePopup);
};

export default (options, $container) => {
  if (!options.popup) {
    return;
  }

  _$dialog = modalDialog(
    Object.assign(
      {
        content: $container[0],
        dialogClass: 'auth-component-popup',
        blockName: 'auth-component-popup-dialog',
        close: function () {
          _removePopupOpenedHistory();
        },
      },
      getLoadedApplicationUtils().mapAccordingToSchema(
        options.popup,
        MAP_TO_DIALOG_OPTIONS
      )
    )
  );
  _addPopupOpenedHistory();
  return _$dialog;
};
