import processFieldData from './process-field-data.js';
import {runAuthorizationCycle} from './process-user-data.js';
import authComponentSuccessAction from './auth-component-success-action.js';

import {
  doOnResult,
  getPasswordAutoFilledState,
  setValidationState,
  doesUserHaveNecessaryField,
  getCurrentGroup,
  typesToNames,
  toggleClearFieldIcon,
  toggleGroupClassName,
  getOptionsByName,
  getElement,
  getCurrentComponent,
  findCurrentComponent,
  resetCaptchaAndClearTokenIfNeeded,
} from '../utils/common-utils.js';

import {removeMessages, showFieldMessage} from '../utils/errors-utils.js';

import {toggleCodeFields} from '../utils/code-field-utils.js';
import {getComponentData} from '../utils/component-data-utils.js';

import {clearUsersList, toggleDisableState} from '../utils/form-state-utils.js';

import {getFieldToValidate} from '../validation.js';

import SELECTORS from '../const/selectors.js';
import ON_EVENT from '../const/on-event.js';

let _processingDataTimeout;

const CLASS_NAMES = {
  withToggledPasswordFormGroup: 'form-group-with-toggled-password',
};

const _isProcessingTheSameField = ($field, $currentComponent) => {
  if (!$field || !$currentComponent.data('lastActiveField')) {
    return;
  }

  return $field[0] === $currentComponent.data('lastActiveField')[0];
};

const _onAfterProcessFieldData = ($currentComponent) => {
  doOnResult(ON_EVENT.afterFieldDataProcessed, $currentComponent);
};

const _processFieldDataAndResultOfProcessing = ($field, $currentComponent) => {
  processFieldData($field, $currentComponent).then(() =>
    _onAfterProcessFieldData($currentComponent)
  );
};

const _needToSetPasswordAutoFilledStateOnInput = ($field) => {
  return (
    !getPasswordAutoFilledState(getCurrentComponent($field)) &&
    $field.is(SELECTORS.passwordField)
  );
};

const _onSetPasswordAutoFilledState = ($field) => {
  _needToSetPasswordAutoFilledStateOnInput($field) &&
    getPasswordAutoFilledState(getCurrentComponent($field));
};

export const onProcessingDataFieldEvent = (
  event,
  $field,
  $currentComponent
) => {
  _isProcessingTheSameField($field, $currentComponent) &&
    clearTimeout(_processingDataTimeout);

  if (event.type === 'change') {
    _processFieldDataAndResultOfProcessing($field, $currentComponent);
  } else {
    _processingDataTimeout = setTimeout(() => {
      _processFieldDataAndResultOfProcessing($field, $currentComponent);
    }, 500);
  }
};

export function disableFieldSynchronization() {
  $(this).data({no_synchronization: true});
}

export function onInputWithTarget() {
  const $field = $(this);
  getCurrentComponent($field).data({
    lastActiveField: getFieldToValidate($field),
  });
  _onSetPasswordAutoFilledState($field);
}

export const writeDataInStore = ($store, $currentComponent) => {
  $store.data('authComponent', {user: getComponentData($currentComponent)});
};

const _generateCode = (url, verifyUser, deliveryMethod, $currentComponent) => {
  if (verifyUser && app.modules.recaptcha) {
    const $$response = $.Deferred();

    app.modules.recaptcha.getScript().then(function () {
      app.modules.recaptcha.getToken('auth_component').then(function (token) {
        $.ajax({
          url: url,
          method: 'post',
          data: {
            user_id: getElement('userField', $currentComponent).val(),
            delivery_method: deliveryMethod,
            response_token: token,
          },
        }).then(
          (response) => {
            $$response.resolve(response);
          },
          (response) => {
            $$response.resolve(response);
          }
        );
      });
    });
    return $$response;
  } else {
    const data = {
      user_id: getElement('userField', $currentComponent).val(),
    };

    if (app.config.smartCaptchaTokenForSendCodeLink) {
      data.user_token = app.config.smartCaptchaTokenForSendCodeLink;
    }

    return $.post(url, data);
  }
};

export const onGenerateCode = ($this, $currentComponent) => {
  const verifyUser = app.config.authComponent.authCodeRecaptchaEnabled;
  let isRestorePasswordSmsLink,
    isRestorePasswordEmailLink,
    $restorePasswordElement,
    $restorePasswordLinksEmail,
    restorePassword,
    deliveryMethod;
  if (app.config.authComponent.data.sendSmsWithPassword) {
    isRestorePasswordSmsLink = $this.is(SELECTORS.restorePasswordSmsLink);
    isRestorePasswordEmailLink = $this.is(SELECTORS.restorePasswordEmailLink);
    deliveryMethod = $this.data('delivery-method');
    $restorePasswordLinksEmail = $currentComponent.find(
      SELECTORS.restorePasswordLink + '[data-delivery-method="email"]'
    );
    $restorePasswordElement =
      ($this.is(SELECTORS.restorePasswordLink) && $this) ||
      ((isRestorePasswordSmsLink || isRestorePasswordEmailLink) &&
        $this.closest('.js-restore-password-auth-sms-and-email'));
    restorePassword = !!$restorePasswordElement.length;

    $restorePasswordElement.addClass('dn');
    $(SELECTORS.restorePasswordTextPrefix).addClass('dn');

    deliveryMethod === 'email' && $restorePasswordLinksEmail.addClass('dn');

    isRestorePasswordSmsLink &&
      $restorePasswordLinksEmail
        .text(app.config.authComponent.data.restoreText.email)
        .removeClass('dn');

    isRestorePasswordEmailLink &&
      $currentComponent
        .find(SELECTORS.restorePasswordLink + '[data-delivery-method="sms"]')
        .text(app.config.authComponent.data.restoreText.sms)
        .removeClass('dn');
  } else {
    restorePassword = $this.addClass('dn').is(SELECTORS.restorePasswordLink);
  }

  removeMessages(
    getElement('passwordGroup, codeGroup', $currentComponent),
    $currentComponent
  );
  _generateCode(
    restorePassword ? app.config.api.passwordURL : app.config.api.authCodeURL,
    verifyUser,
    $this.data('delivery-method'),
    $currentComponent
  )
    .then((response) => {
      const showPasswordField =
          restorePassword &&
          !isRestorePasswordSmsLink &&
          deliveryMethod !== 'sms',
        $group = getElement(
          (showPasswordField ? 'password' : 'code') + 'Group',
          $currentComponent
        ),
        error = response.responseJSON && response.responseJSON.error;

      toggleCodeFields($currentComponent, showPasswordField);

      if (error && error.message === 'bot request') {
        showFieldMessage($group, $group.data('bot-detected-message'));
      } else {
        showFieldMessage(
          $group,
          response.message || $group.data('success-message'),
          'success'
        );
      }
    })
    .always(() => {
      $doc.trigger('generateCodeComplete:authComponent');
      resetCaptchaAndClearTokenIfNeeded('smartCaptchaTokenForSendCodeLink');
    });
};

export const userDoesntExist = ($currentComponent) => {
  setValidationState($currentComponent, true);
  clearUsersList($currentComponent);
  $doc.trigger('denyUser:authComponent');
};

const _prepareAuthFields = (user, $currentComponent) => {
  if (doesUserHaveNecessaryField('email')) {
    getCurrentGroup(
      getElement('emailField', $currentComponent).val(user.email)
    ).data({valid: true});
    getCurrentGroup(
      getElement('phoneField', $currentComponent).prop({readonly: user.exists})
    ).data({valid: true});
  } else {
    getCurrentGroup(
      getElement('phoneField', $currentComponent).val(user.phone)
    ).data({valid: true});
    getCurrentGroup(
      getElement('emailField', $currentComponent).prop({readonly: user.exists})
    ).data({valid: true});
  }
};

export const userExists = ($currentComponent) => {
  const data = getElement('userExistRadio:checked', $currentComponent).data();

  data.exists = true;
  setComponentState($currentComponent, data, function (user) {
    _prepareAuthFields(user, $currentComponent);
    clearUsersList($currentComponent);
  });
  $doc.trigger('confirmUser:authComponent');
};

const _onProcessSubmitResultsWithSuccess = ($container) => {
  $container.trigger('afterAuthorizeWithSuccess:authComponent');

  authComponentSuccessAction(findCurrentComponent($container));
};

const _onProcessSubmitResultsWithError = (errors, $container) => {
  $container.trigger('afterAuthorizeWithError:authComponent', [errors]);
};

const _processSubmitResults = ($$processUser, $container) => {
  $$processUser
    .done(() => _onProcessSubmitResultsWithSuccess($container))
    .fail((errors) => _onProcessSubmitResultsWithError(errors, $container));
};

function _processUserDataOnSubmit($currentComponent, $store) {
  const $$processUser = $.Deferred();

  runAuthorizationCycle($$processUser, $currentComponent, $store);

  _processSubmitResults($$processUser, $(this));
}

export const onSubmit = ($container, $currentComponent, $store) => {
  toggleDisableState($currentComponent, ['submitButton', true]);
  doOnResult('onSubmit', $currentComponent);
  _processUserDataOnSubmit.call($container, $currentComponent, $store);
};

const _clearField = ($field) => {
  $field.val('').trigger('input').focus();
};

export function onClearField() {
  const $field = $(this);
  const $fieldToClear = getElement(
    typesToNames(getCurrentGroup($field).data('type')),
    getCurrentComponent($field)
  );

  _clearField($fieldToClear);
  _onSetPasswordAutoFilledState($fieldToClear);
}

export function onToggleClearIcon() {
  toggleClearFieldIcon($(this));
}

export function onTogglePassword() {
  const $currentComponent = getCurrentComponent($(this));
  const $passwordField = getElement('passwordField', $currentComponent);

  getElement('passwordField', $currentComponent).attr({
    type: $passwordField.attr('type') === 'text' ? 'password' : 'text',
  });
}

const _toggleIconToTogglePassword = ($field) => {
  toggleGroupClassName($field, CLASS_NAMES.withToggledPasswordFormGroup);
};

export function onToggleIconToTogglePassword() {
  const $field = $(this);
  _toggleIconToTogglePassword($field);
}

const _fieldCanBeSynchronized = (fieldType, $currentComponent) => {
  const $synchronizedField = getElement(fieldType + 'Field', $currentComponent);

  return (
    ~getOptionsByName('fields.toSynchronize', $currentComponent).indexOf(
      fieldType
    ) &&
    $synchronizedField.not('[readonly], [disabled]') &&
    !$synchronizedField.data('no_synchronization')
  );
};

const _synchronizeFields = (fields, $currentComponent) => {
  $.each(fields, function (fieldType, fieldValue) {
    fieldValue &&
      _fieldCanBeSynchronized(fieldType, $currentComponent) &&
      processFieldData(
        getElement(fieldType + 'Field', $currentComponent).val(fieldValue),
        $currentComponent
      );
  });
};

export const onSynchronizeFields = (fields, $store, $currentComponent) => {
  _synchronizeFields(fields, $currentComponent);
  writeDataInStore($store, $currentComponent);
};

export function onCloseComponent($currentComponent) {
  getOptionsByName('popup', $currentComponent) && $(this).dialog('close');
}

export function onUnmount($container) {
  $container.remove();
  $(this).off(
    'processUserData:authComponent synchronizeFields:authComponent unmount:authComponent'
  );
}
