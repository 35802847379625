var Handlebars = require("../../../../../../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "dn";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class='label'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"customFormSettings") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":6},"end":{"line":13,"column":13}}})) != null ? stack1 : "")
    + "      <label class='required' style='"
    + alias2(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customFormSettings") : depth0)) != null ? lookupProperty(stack1,"styles") : stack1)) != null ? lookupProperty(stack1,"labelStyles") : stack1), depth0))
    + "'>\n        "
    + alias2(__default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"auth_component.desktop.password.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":15,"column":56}}}))
    + "\n      </label>\n    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class='required-icon' style='"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customFormSettings") : depth0)) != null ? lookupProperty(stack1,"styles") : stack1)) != null ? lookupProperty(stack1,"requireStyles") : stack1), depth0))
    + "'>*</span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"auth_component.mobile.password.placeholder",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":26,"column":61},"end":{"line":26,"column":114}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n  class='form-group form-group-password js-group-password-auth "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hiddenByDefault") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":63},"end":{"line":2,"column":100}}})) != null ? stack1 : "")
    + "'\n  data-required-data-message=\""
    + alias2(__default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"auth_component.mobile.password.message.required",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":30},"end":{"line":3,"column":88}}}))
    + "\"\n  data-invalid-data-message=\""
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"messages") : stack1)) != null ? lookupProperty(stack1,"invalidPasswordErrorMessage") : stack1), depth0))
    + "\"\n  data-success-message=\""
    + alias2(__default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"auth_component.mobile.code.password.success_request",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":24},"end":{"line":5,"column":86}}}))
    + "\"\n  data-bot-detected-message=\""
    + alias2(__default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"auth_component.mobile.code.message.bot_detected_message",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":29},"end":{"line":6,"column":95}}}))
    + "\" \n  data-type='password'\n>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"fields") : depth0)) != null ? lookupProperty(stack1,"withoutLabels") : stack1),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":2},"end":{"line":18,"column":13}}})) != null ? stack1 : "")
    + "  <div class='text-field-wrapper'>\n"
    + ((stack1 = container.invokePartial(require("../../desktop/_icons-holder.handlebars"),depth0,{"name":"../../desktop/_icons-holder","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <input \n      class='text-field js-input-password-auth'\n      style='"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customFormSettings") : depth0)) != null ? lookupProperty(stack1,"styles") : stack1)) != null ? lookupProperty(stack1,"inputStyles") : stack1), depth0))
    + "' \n      type='password' \n      name='password'\n      placeholder=\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"fields") : depth0)) != null ? lookupProperty(stack1,"withoutPlaceholder") : stack1),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":19},"end":{"line":26,"column":125}}})) != null ? stack1 : "")
    + "\" \n      value='"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fields") : depth0)) != null ? lookupProperty(stack1,"values") : stack1)) != null ? lookupProperty(stack1,"password") : stack1), depth0))
    + "' \n      autocomplete='"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"fields") : depth0)) != null ? lookupProperty(stack1,"autocomplete") : stack1), depth0))
    + "'\n    />\n  </div>\n  <div class='success-block js-success-message'></div>\n  <div class='error-block js-error-message'></div>\n\n"
    + ((stack1 = container.invokePartial(require("../../desktop/_restore-code-links.handlebars"),depth0,{"name":"../../desktop/_restore-code-links","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});