var Handlebars = require("../../../../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='social-networks-auth-component js-social-networks-auth-component social-networks-auth-component-"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "'>\n  <div class='social-networks-auth-component-flash-message'>\n    <div class='apress-flash-message with-icon js-social-networks-auth-component-flash-message-body'></div>\n  </div>\n  <div class=\"social-networks-auth-component-items-wrapper\">\n"
    + ((stack1 = container.invokePartial(require("./_provider-items.handlebars"),depth0,{"name":"_provider-items","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n</div>\n";
},"usePartial":true,"useData":true});