import {
  optionalTypesToNames,
  toggleComponentLoadingIndicator,
  getElement,
} from './common-utils.js';

import {removeMessages, toggleFlashMessage} from './errors-utils.js';

export const toggleDisableState = ($currentComponent, ...data) => {
  data.forEach((arr) => {
    let [elementName, state] = arr;
    getElement($.trim(elementName), $currentComponent).prop({disabled: state});
  });
};

export const clearUsersList = ($currentComponent) => {
  getElement('existingUsersContainer', $currentComponent).empty();
};

export const getFieldsToCheckFormat = ($currentComponent) =>
  getElement(
    optionalTypesToNames($currentComponent, 'toCheckFormat'),
    $currentComponent
  );

export const resetFormState = ($currentComponent) => {
  removeMessages($currentComponent);
  toggleFlashMessage($currentComponent);
  toggleDisableState(
    $currentComponent,
    ['phoneField', false],
    ['emailField', false]
  );
  clearUsersList($currentComponent);
  getFieldsToCheckFormat($currentComponent).prop({readonly: false});
  toggleComponentLoadingIndicator(true, $currentComponent);
};
