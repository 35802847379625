import authComponentDesktopExistingUser from '../templates/auth-component/desktop/existing-users.handlebars';
import authComponentMobileExistingUser from '../templates/auth-component/mobile/existing-users.handlebars';
import authComponentDesktopForm from '../templates/auth-component/desktop/form.handlebars';
import authComponentDesktopSimple from '../templates/auth-component/desktop/simple.handlebars';
import authComponentMobileForm from '../templates/auth-component/mobile/form.handlebars';
import authComponentMobileSimple from '../templates/auth-component/mobile/simple.handlebars';

export default {
  desktop: {
    simple: authComponentDesktopSimple,
    form: authComponentDesktopForm,
    existingUser: authComponentDesktopExistingUser,
  },
  mobile: {
    simple: authComponentMobileSimple,
    form: authComponentMobileForm,
    existingUser: authComponentMobileExistingUser,
  },
};
