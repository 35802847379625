import {needToValidateWithUserSearching} from '../utils/search-user-utils.js';

import {doOnResult} from '../utils/common-utils.js';

import {validateFieldDataFormat} from '../validation.js';

import searchUser from './search-user.js';

import ON_EVENT from '../const/on-event.js';

export default ($field, $currentComponent) => {
  doOnResult(ON_EVENT.processFieldData, $currentComponent, $field);

  return needToValidateWithUserSearching($field)
    ? validateFieldDataFormat($field, $currentComponent).then(() =>
        searchUser($currentComponent)
      )
    : validateFieldDataFormat($field, $currentComponent);
};
