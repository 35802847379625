var Handlebars = require("../../../../../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <li class='radio'>\n        <input \n          class='js-user-exist-radio-auth'\n          type='radio'\n          name='users_exists'\n          id='user-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "'\n          data-id='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "'\n          data-email='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "'\n          data-phone='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"phone") : depth0), depth0))
    + "'\n        />\n        <label for='user-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"email") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":18,"column":10},"end":{"line":22,"column":17}}})) != null ? stack1 : "")
    + "        </label>\n      </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"phone") : depth0), depth0))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='form-group existing-users'>\n  <p>\n    "
    + alias2(__default(require("../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"auth_component.mobile.existing_users.user_already_exists",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":71}}}))
    + "\n  </p>\n  <ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"users") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":25,"column":13}}})) != null ? stack1 : "")
    + "  </ul>\n  \n  <button class='apress-button ab-default js-button-yes-users-exists' type='button' disabled='true'>\n    "
    + alias2(__default(require("../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"auth_component.mobile.existing_users.confirm",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":29,"column":4},"end":{"line":29,"column":59}}}))
    + "\n  </button>\n  <button class='apress-button ab-default secondary js-button-no-users-exists' type='button'>\n    "
    + alias2(__default(require("../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"auth_component.mobile.existing_users.deny",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":32,"column":4},"end":{"line":32,"column":56}}}))
    + "\n  </button>\n</div>\n";
},"useData":true});