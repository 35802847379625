import {
  extendArrayWithNull,
  noNeedToExtendArrayWithNull,
  removeNullValues,
  addPasswordField,
  mapPasswordToPasswordAndCode,
  clearAdditionalFieldsParams,
  removePhoneType,
  mapEmailToMultipleType,
  addDashedNameToKeys,
} from '../utils/auth-component-render-utils.js';

import {
  getLoadedApplicationUtils,
  doesUserHaveNecessaryField,
  getPlatform,
  findCurrentComponent,
  doOnResult,
  getOptionsByName,
  toggleComponentLoadingIndicator,
  toggleClearFieldIcon,
  getElement,
} from '../utils/common-utils.js';

import {setDefaultContentType} from '../utils/content-types-utils.js';
import {getProcessDescriptionData} from '../utils/process-description-data-utils.js';

import authComponentSuccessAction from './auth-component-success-action.js';
import createAuthComponentDialog from './auth-component-popup.js';
import mutateMultipleField from './multiple-field.js';
import searchUser from './search-user.js';
import currentComponentListener from './current-component-listener.js';

import {validateDataFormat} from '../validation.js';

import ON_EVENT from '../const/on-event.js';
import TEMPLATES from '../const/templates.js';

let _$currentComponent,
  _$store = $('<store>');

const FIELDS_TO_RENDER = ['name', 'email', 'phone', 'password'],
  OPTIONS_TO_EXCLUDE_FROM_EXTENDING_WITH_CODE_FIELD = ['toCheckFormat'],
  DATA_SCOPE_NAME = 'auth-component',
  CLASS_NAMES = {
    initialLoading: 'auth-component-initial-loading',
  };

const _default = {
  type: 'simple',
  fields: {
    set: ['name', 'email', 'phone'],
    required: ['name', 'email', 'phone'],
    toSynchronize: ['name', 'email', 'phone'],
    toCheckFormat: ['name', 'email', 'phone', 'password'],
    selfCleared: ['email', 'phone'],
    maxlength: {name: 200},
    autocomplete: 'on',
    hiddenByDefault: [],
    visibleForNewUsers: ['name'],
    visibleIfDataCorrect: [],
    hints: null,
    withoutLabels: false,
    withoutPlaceholder: false,
  },
  formClass: 'apress-form clearfix',
  buttonClass: 'apress-button ab-default',
  orientation: 'horizontal',
  selectors: {flashMessage: null},
  crossDomainAuth: false,
  contentType: null,
  toggleContent: false,
  withMultipleField: false,
  withToggledPassword: true,
  actionOnSuccess: null,
  popup: null,
  error: null,
  title: null,
  buttonText: null,
  simpleRegistration: false,
  withoutSpinner: false,
  getAdditionalUserData: function () {},
  withSocialRegistration: false,
  newSocialRegistrationSchema: false,
  disableSmartCaptcha: true,
  onMount: function () {},
  onRender: function () {},
  onSearch: function () {},
  onCompleted: function () {},
  onProcessUserData: function () {},
  onProcessFieldData: function () {},
  onAfterFieldDataProcessed: function () {},
  onBeforeProcessDataOnRender: function () {},
  onSubmit: function () {},
  onError: function () {},
};

const _renderComponent = ($container, options) => {
  options = _prepareOptions(options, $container.data(DATA_SCOPE_NAME));
  _$currentComponent = findCurrentComponent(
    _renderSubComponents(
      $container.html(
        $(
          TEMPLATES[getPlatform()][options.type](_prepareDataToRender(options))
        ).data({options: options})
      ),
      options
    )
  );

  doOnResult(ON_EVENT.mount, _$currentComponent);
  toggleComponentLoadingIndicator(
    true,
    _$currentComponent,
    CLASS_NAMES.initialLoading
  );

  _initPlugins($container, options);

  // we need timeout to process autofill form scenario
  setTimeout(function () {
    _onBeforeProcessDataOnRender();
    _processDataOnRender().then(
      _onAfterProcessDataOnRender.bind(null, $container)
    );
  }, 400);
};

const _renderSubComponents = ($container, options) => {
  return options.withSocialRegistration
    ? _renderSocialNetworksAuthComponent($container, options)
    : $container;
};

const _renderSocialNetworksAuthComponent = ($container, options) => {
  return $container
    .find('.js-social-networks-auth-component-container')
    .trigger('render:socialNetworksAuthComponent', [
      {
        type: 'simple',
        onSuccess: function (data) {
          options[ON_EVENT.completed](getProcessDescriptionData(data.user));
          authComponentSuccessAction(findCurrentComponent($container));
        },
        onError: options[ON_EVENT.error],
        newSchema: options.newSocialRegistrationSchema,
      },
    ])
    .end();
};

const _prepareDataToRender = (options) => {
  const config = app.config.authComponent || {};
  if (config.content) {
    config.content = addDashedNameToKeys(config.content);
  }

  return _prepareTipContent(
    $.extend(
      {},
      $.extend(true, {}, config, options),
      {platform: getPlatform()},
      _$store.data('authComponent')
    )
  );
};

const _prepareTipContent = (options) => {
  for (let contentType in options.content) {
    if (options.content[contentType].tip) {
      options.content[contentType].tip = options.content[
        contentType
      ].tip.replace('%buttonText%', options.content[contentType].buttonText);
    }
  }

  return options;
};

const _initPlugins = ($container, options) => {
  options.fields.hints &&
    _$currentComponent.find('.js-qtip-icon').trigger('init:initQtip');
  getElement('phoneField', _$currentComponent).trigger('init:phoneMask');

  createAuthComponentDialog(options, $container);
};

const _prepareOptions = (options, containerData) => {
  const resultOptions = _extendOptionsWithCodeField(
    addPasswordField(
      removeNullValues(
        $.extend(
          true,
          {},
          _default,
          options && _prepareForDeepExtending(options),
          containerData
        )
      )
    ),
    OPTIONS_TO_EXCLUDE_FROM_EXTENDING_WITH_CODE_FIELD
  );

  resultOptions.simpleType = options.type === 'simple';
  resultOptions.verticalOrientation = resultOptions.orientation === 'vertical';

  return _getFieldsExtended(
    _convertEmailAndPhoneToMultipleField(
      _filterComponentFields(
        resultOptions,
        [
          'fields.toSynchronize',
          'fields.toCheckFormat',
          'fields.required',
          'fields.visibleForNewUsers',
          'fields.hiddenByDefault',
          'fields.set',
        ],
        _getFieldsToExclude()
      )
    )
  );
};

const _getFieldsExtended = (options) => {
  const fields = options.fields;
  options.fieldsExtended = fields.set.reduce((result, fieldName) => {
    const fieldOptions = {
      name: fieldName,
    };
    fieldOptions[`${fieldName}Field`] = true;
    fieldOptions.hiddenByDefault = !!~fields.hiddenByDefault.indexOf(fieldName);
    fieldOptions.required = !!~fields.required.indexOf(fieldName);

    result.push(fieldOptions);
    return result;
  }, []);

  return options;
};

const _convertEmailAndPhoneToMultipleField = (options) => {
  if (!options.withMultipleField) {
    return options;
  }

  options.fields = Object.keys(options.fields).reduce(function (
    result,
    fieldsSetKey
  ) {
    result[fieldsSetKey] = Array.isArray(options.fields[fieldsSetKey])
      ? options.fields[fieldsSetKey]
          .map(mapEmailToMultipleType)
          .filter(removePhoneType)
      : options.fields[fieldsSetKey];

    return result;
  },
  {});

  return options;
};

// Need first array(defaultValue) has length less or equal second array length(newValue) to merge them properly.
// As result second array will rewrite first array, instead of extending it, when we'll use $.extend(true, ...).
const _prepareForDeepExtending = (options) => {
  const newFieldsData = options.fields || {};

  options.fields = Object.keys(newFieldsData).reduce(function (
    result,
    currentKey
  ) {
    const defaultValue = _default.fields[currentKey],
      newValue = newFieldsData[currentKey];

    result[currentKey] = noNeedToExtendArrayWithNull(defaultValue, newValue)
      ? newValue
      : extendArrayWithNull(defaultValue, newValue);

    return result;
  },
  {});

  return options;
};

const _extendOptionsWithCodeField = (options, optionsToExclude) => {
  const fieldsOptions = options.fields;

  options.fields = Object.keys(fieldsOptions).reduce(function (
    preparedOptions,
    currentOptionKey
  ) {
    const currentOptionValue = fieldsOptions[currentOptionKey];

    preparedOptions[currentOptionKey] =
      Array.isArray(currentOptionValue) &&
      !getLoadedApplicationUtils().isInArray(currentOptionKey, optionsToExclude)
        ? mapPasswordToPasswordAndCode(currentOptionValue)
        : currentOptionValue;

    return preparedOptions;
  },
  {});

  return options;
};

const _getFieldsToExclude = () => {
  return FIELDS_TO_RENDER.filter(function (fieldName) {
    return _shouldFieldBeExcluded(fieldName);
  });
};

const _shouldFieldBeExcluded = (fieldName) => {
  switch (fieldName) {
    case 'name':
      return doesUserHaveNecessaryField('withName');
    default:
      return doesUserHaveNecessaryField(fieldName);
  }
};

const _filterComponentFields = (options, fieldsToFilter, fieldsToExclude) => {
  return !fieldsToExclude || !fieldsToExclude.length
    ? options
    : fieldsToFilter.reduce(function (result, optionsPair) {
        const parts = optionsPair.split('.'),
          searchingArea = parts[0],
          groupOfFields = parts[1];

        result[searchingArea][groupOfFields] = result[searchingArea][
          groupOfFields
        ].filter(function (fieldName) {
          return (
            fieldName &&
            !~fieldsToExclude.indexOf(clearAdditionalFieldsParams(fieldName))
          );
        });
        return result;
      }, options);
};

const _onBeforeProcessDataOnRender = () => {
  getOptionsByName('withMultipleField', _$currentComponent) &&
    mutateMultipleField(null, _$currentComponent);
  setDefaultContentType(_$currentComponent);
  doOnResult('onBeforeProcessDataOnRender', _$currentComponent);
};

const _onAfterProcessDataOnRender = ($container) => {
  _$currentComponent = findCurrentComponent($container);
  toggleComponentLoadingIndicator(
    false,
    _$currentComponent,
    CLASS_NAMES.initialLoading
  );
  _onSetSelfClearedFieldsState();
  currentComponentListener(_$currentComponent, $container, _$store);
  doOnResult(
    ON_EVENT.render,
    _$currentComponent,
    _markComponentAsInitialized(_$currentComponent)
  );
};

const _processDataOnRender = () => {
  const $$processDataOnRender = $.Deferred();

  validateDataFormat(['nameField', 'passwordField'], _$currentComponent).always(
    function () {
      validateDataFormat(['phoneField', 'emailField'], _$currentComponent)
        .then(function () {
          searchUser(_$currentComponent);
        })
        .always($$processDataOnRender.resolve);
    }
  );

  return $$processDataOnRender;
};

const _markComponentAsInitialized = ($component) =>
  $component.data({initialized: true});

const _setSelfClearedFieldsState = (selfClearedFieldsTypes) => {
  selfClearedFieldsTypes.forEach(function (type) {
    toggleClearFieldIcon(getElement(type + 'Field', _$currentComponent));
  });
};

const _onSetSelfClearedFieldsState = () => {
  const selfClearedFieldsTypes = getOptionsByName(
    'fields.selfCleared',
    _$currentComponent
  );

  selfClearedFieldsTypes && _setSelfClearedFieldsState(selfClearedFieldsTypes);
};

export default (event, options, $store) => {
  if (doesUserHaveNecessaryField('email')) {
    return;
  }

  $store && (_$store = $store);

  _renderComponent($(event.target), options);
};
