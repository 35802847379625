import {
  getPlatform,
  doOnResult,
  doesUserHaveNecessaryField,
  createValidationError,
  optionalTypesToNames,
  typesToNames,
  setValidationState,
  getOptionsByName,
  getElement,
} from '../utils/common-utils.js';

import {
  getByPhoneAndEmail,
  getByEmail,
  getByPhone,
  isFieldRequired,
} from '../utils/search-user-utils.js';

import {toggleDisableState} from '../utils/form-state-utils.js';
import {showErrors} from '../utils/errors-utils.js';
import {setDefaultContentType} from '../utils/content-types-utils.js';
import {setComponentState} from '../utils/component-state-utils.js';

import ON_EVENT from '../const/on-event.js';
import SEARCH_ACTIONS from '../const/search-actions.js';
import ON_SEARCH_EVENT_TYPES from '../const/on-search-event-types.js';
import TEMPLATES from '../const/templates.js';

const _renderExistingUsers = (users, $currentComponent) => {
  getElement('existingUsersContainer', $currentComponent).html(
    TEMPLATES[getPlatform()].existingUser({users: users})
  );
};

const _searchUserByEmail = (email) => {
  const $$searchUser = $.Deferred();

  getByEmail(email).then(function (response) {
    const user = response.users && response.users[0];
    $$searchUser.resolve({
      type: user ? SEARCH_ACTIONS.foundByEmail : SEARCH_ACTIONS.notFound,
      user: user,
    });
  });
  return $$searchUser;
};

const _searchUserByPhone = (phone) => {
  const $$searchUser = $.Deferred();

  getByPhone(phone).then(function (response) {
    if (response.users.length) {
      $$searchUser.resolve({
        type: SEARCH_ACTIONS.foundInUsers,
        user: response.users[0],
      });
    } else {
      $$searchUser.resolve({
        type: SEARCH_ACTIONS.notFound,
        users: [],
      });
    }
  });

  return $$searchUser;
};

const _searchUserByPhoneAndEmail = (phone, email) => {
  const promise = $.Deferred();

  getByPhoneAndEmail(phone, email).then(function (response) {
    if (response.users.length) {
      promise.resolve({
        type: SEARCH_ACTIONS.foundByPhoneAndEmail,
        user: response.users[0],
      });
    } else {
      getByEmail(email).then(function (response) {
        const userDetectedByEmail = response.users;

        if (userDetectedByEmail.length) {
          getByPhone(phone).then(function (response) {
            if (response.users.length) {
              promise.resolve({
                type: SEARCH_ACTIONS.foundByEmailAndAlsoByPhone,
                user: userDetectedByEmail[0],
              });
            } else {
              promise.resolve({
                type: SEARCH_ACTIONS.foundByEmailButNotByPhone,
                user: userDetectedByEmail[0],
              });
            }
          });
        } else {
          promise.resolve({
            type: SEARCH_ACTIONS.notFound,
            phone: phone,
          });
        }
      });
    }
  });

  return promise;
};

const _onAfterSearchingUserByPhone = (action, $currentComponent) => {
  switch (action.type) {
    case SEARCH_ACTIONS.foundInUsers:
      setComponentState(
        $currentComponent,
        action.user,
        function () {
          doOnResult(ON_EVENT.search, $currentComponent, {
            type: ON_SEARCH_EVENT_TYPES.phoneInUsers,
            user: action.user,
          });
          toggleDisableState($currentComponent, [
            'emailField',
            doesUserHaveNecessaryField('email', action.user),
          ]);

          $doc.trigger('phoneFoundInUsers:authComponent', [$currentComponent]);
        },
        'byPhone'
      );
      break;
    case SEARCH_ACTIONS.foundInContacts:
      setComponentState($currentComponent, null, function () {
        doOnResult(ON_EVENT.search, $currentComponent, {
          type: ON_SEARCH_EVENT_TYPES.phoneInContacts,
          users: action.users,
        });
        _renderExistingUsers(action.users, $currentComponent);
      });
      break;
    case SEARCH_ACTIONS.notFound:
      setComponentState($currentComponent, null, function () {
        doOnResult(ON_EVENT.search, $currentComponent, {
          type: ON_SEARCH_EVENT_TYPES.phone,
          user: null,
        });
        setValidationState($currentComponent, true);
      });
  }
};

const _onAfterSearchingUserByPhoneAndEmail = (action, $currentComponent) => {
  switch (action.type) {
    case SEARCH_ACTIONS.foundByEmailButNotByPhone:
    case SEARCH_ACTIONS.foundByPhoneAndEmail:
      setComponentState(
        $currentComponent,
        action.user,
        function (user) {
          doOnResult(ON_EVENT.search, $currentComponent, {
            type:
              action.type === SEARCH_ACTIONS.foundByEmailButNotByPhone
                ? ON_SEARCH_EVENT_TYPES.emailButNotPhone
                : ON_SEARCH_EVENT_TYPES.emailAndPhone,
            user: user,
          });
          toggleDisableState($currentComponent, [
            'phoneField',
            !isFieldRequired('phone', $currentComponent) && user && user.phone,
          ]);
        },
        'byMail'
      );
      break;
    case SEARCH_ACTIONS.foundByEmailAndAlsoByPhone:
      setComponentState(
        $currentComponent,
        action.user,
        function () {
          showErrors(
            createValidationError(
              {
                errorAttribute: 'phone',
                message: getElement('phoneGroup', $currentComponent).data(
                  'unique-data-message'
                ),
              },
              $currentComponent
            )
          );
          getElement(
            optionalTypesToNames(
              $currentComponent,
              'visibleIfDataCorrect',
              'group'
            ),
            $currentComponent
          ).addClass('dn');
          setValidationState($currentComponent);
        },
        'byMail'
      );
      break;
    case SEARCH_ACTIONS.notFound:
      return _searchUserByPhone(action.phone).then((action) =>
        _onAfterSearchingUserByPhone(action, $currentComponent)
      );
  }
};

const _hideFieldAccordingToUserInfo = (
  formFieldName,
  userFieldName,
  $currentComponent
) => {
  if (userFieldName && !doesUserHaveNecessaryField(userFieldName)) {
    return;
  }

  getElement(typesToNames(formFieldName, 'group'), $currentComponent).addClass(
    'dn'
  );
};

const _hideFieldsForDefaultState = (fields, $currentComponent) => {
  fields.forEach(function (instruction) {
    const instructionPair = instruction.split(':');

    _hideFieldAccordingToUserInfo(
      instructionPair[0],
      instructionPair[1],
      $currentComponent
    );
  });
};

const _onAfterSearchingUserByEmail = (action, $currentComponent) => {
  switch (action.type) {
    case SEARCH_ACTIONS.foundByEmail:
    case SEARCH_ACTIONS.notFound:
      setComponentState(
        $currentComponent,
        action.user,
        function (user) {
          doOnResult(ON_EVENT.search, $currentComponent, {
            type: ON_SEARCH_EVENT_TYPES.email,
            user: user || null,
          });
          toggleDisableState($currentComponent, [
            'phoneField',
            !isFieldRequired('phone', $currentComponent) && user && user.phone,
          ]);
          setValidationState($currentComponent, true);
        },
        'byMail'
      );
  }
};

function _setDefaultState($currentComponent) {
  const fieldsToHideByDefault = getOptionsByName(
    'fields.hiddenByDefault',
    $currentComponent
  );

  fieldsToHideByDefault.length &&
    _hideFieldsForDefaultState(fieldsToHideByDefault, $currentComponent);
  setDefaultContentType($currentComponent);
}

export default ($currentComponent) => {
  const phone = $.trim(getElement('phoneField', $currentComponent).val()),
    email = $.trim(getElement('emailField', $currentComponent).val());

  if (phone && email) {
    return _searchUserByPhoneAndEmail(phone, email).then((action) =>
      _onAfterSearchingUserByPhoneAndEmail(action, $currentComponent)
    );
  }

  if (phone) {
    return _searchUserByPhone(phone).then((action) =>
      _onAfterSearchingUserByPhone(action, $currentComponent)
    );
  }

  if (email) {
    return _searchUserByEmail(email).then((action) =>
      _onAfterSearchingUserByEmail(action, $currentComponent)
    );
  }

  setComponentState($currentComponent, null, () =>
    _setDefaultState($currentComponent)
  );

  return $.Deferred().resolve();
};
