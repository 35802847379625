var Handlebars = require("../../../../../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "  <span class='password-restore-links dn js-restore-password-auth-sms-and-email'>\n    <div class='password-restore-links-wrapper'>\n      <span class='js-restore-text-prefix'></span>\n      "
    + ((stack1 = __default(require("../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"auth_component.restore_password_sms_and_email",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":5,"column":64}}})) != null ? stack1 : "")
    + "\n    </div>\n  </span>\n  <span class='password-link dn js-restore-password-auth' data-delivery-method='email'>\n    <div class='password-restore-links-wrapper'>\n      <span class='password-restore-link-prefix js-restore-text-prefix'></span>\n      "
    + ((stack1 = __default(require("../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"auth_component.restore_password_email_short",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":6},"end":{"line":11,"column":62}}})) != null ? stack1 : "")
    + "\n    </div>\n  </span>\n  <span class='password-link dn js-restore-password-auth' data-delivery-method='sms'>\n    <div class='password-restore-links-wrapper'>\n      <span class='password-restore-link-prefix js-restore-text-prefix'></span>\n      "
    + ((stack1 = __default(require("../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"auth_component.restore_password_sms_short",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":6},"end":{"line":17,"column":60}}})) != null ? stack1 : "")
    + "\n    </div>\n  </span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "  <span class='password-link right- dn js-restore-password-auth'>\n    "
    + container.escapeExpression(__default(require("../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"auth_component.restore_password",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":22,"column":4},"end":{"line":22,"column":46}}}))
    + "\n  </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"sendSmsWithPassword") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":24,"column":7}}})) != null ? stack1 : "")
    + "<span class='password-link dn js-get-code-auth'>\n  "
    + container.escapeExpression(__default(require("../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"auth_component.link.send_auth_code",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":26,"column":2},"end":{"line":26,"column":47}}}))
    + "\n</span>\n";
},"useData":true});