/**
 * Вспомогательный модуль для получения доступа к контейнерам
 * статического и динамического авторизационных компонентов.
 */
import {getLoadedApplicationUtils} from '../../utils/common-utils.js';

app.modules.authComponentContainer = (function (self) {
  function _getPathToContainer(containerName) {
    return 'app.modules.' + containerName + 'AuthComponentContainer';
  }

  function _getComponentContainer(containerName) {
    const pathToContainer = _getPathToContainer(containerName),
      container = getLoadedApplicationUtils().getFromObjectByPath(
        pathToContainer,
        window
      );

    containerName &&
      getLoadedApplicationUtils().invariant(
        getLoadedApplicationUtils().isPlainObject(container),
        '%s should be initialized',
        pathToContainer
      );

    return container;
  }

  self.getComponentContainer = _getComponentContainer;

  return self;
})(app.modules.authComponentContainer || {});
