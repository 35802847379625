const fromCamelToDash = (string) => {
  if (!string) {
    return;
  }

  return string.replace(/([A-Z])/g, ($1) => {
    return '-' + $1.toLowerCase();
  });
};

export const extendArrayWithNull = (defaultValue, newValue) => {
  return defaultValue.map(function (item, index) {
    return newValue[index] ? newValue[index] : null;
  });
};

export const noNeedToExtendArrayWithNull = (defaultValue, newValue) => {
  return !Array.isArray(defaultValue) || defaultValue.length <= newValue.length;
};

export const removeNullValues = (options) => {
  options.fields = Object.keys(options.fields).reduce((result, currentKey) => {
    result[currentKey] = Array.isArray(options.fields[currentKey])
      ? options.fields[currentKey].filter((value) => !!value)
      : options.fields[currentKey];

    return result;
  }, {});

  return options;
};

export const addPasswordField = (options) => {
  ['set', 'required'].forEach((fieldsSet) => {
    !~options.fields[fieldsSet].indexOf('password') &&
      options.fields[fieldsSet].push('password');
  });

  return options;
};

export const mapPasswordToPasswordAndCode = (options) => {
  return options.reduce((result, currentValue) => {
    return result.concat(
      currentValue === 'password' ? ['password', 'code'] : currentValue
    );
  }, []);
};

export const clearAdditionalFieldsParams = (fieldName) =>
  fieldName.split(':')[0];

export const removePhoneType = (fieldType) => fieldType !== 'phone';

export const mapEmailToMultipleType = (fieldType) => {
  if (fieldType !== 'email') {
    return fieldType;
  }

  return 'multiple';
};

export const addDashedNameToKeys = (object) => {
  for (const key in object) {
    object[key].dahedCaseName = fromCamelToDash(key);
  }
  return object;
};
