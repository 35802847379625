var Handlebars = require("../../../../../../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "dn";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class='label'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"required") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":6},"end":{"line":13,"column":13}}})) != null ? stack1 : "")
    + "      <label class='"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"required") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":14,"column":56}}})) != null ? stack1 : "")
    + "' style='"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customFormSettings") : depth0)) != null ? lookupProperty(stack1,"styles") : stack1)) != null ? lookupProperty(stack1,"labelStyles") : stack1), depth0))
    + "'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"customFormSettings") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":19,"column":15}}})) != null ? stack1 : "")
    + "      </label>\n    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"customFormSettings") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":12,"column":15}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <span class='required-icon' style='"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customFormSettings") : depth0)) != null ? lookupProperty(stack1,"styles") : stack1)) != null ? lookupProperty(stack1,"requireStyles") : stack1), depth0))
    + "'>*</span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "required";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customFormSettings") : depth0)) != null ? lookupProperty(stack1,"fieldsSettings") : stack1)) != null ? lookupProperty(stack1,"name") : stack1)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "          "
    + container.escapeExpression(__default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"auth_component.desktop.name.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":10},"end":{"line":18,"column":54}}}))
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"customFormSettings") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":34,"column":61},"end":{"line":34,"column":215}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customFormSettings") : depth0)) != null ? lookupProperty(stack1,"fieldsSettings") : stack1)) != null ? lookupProperty(stack1,"name") : stack1)) != null ? lookupProperty(stack1,"placeholder") : stack1), depth0));
},"16":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"auth_component.mobile.name.placeholder",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":34,"column":159},"end":{"line":34,"column":208}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n  class='form-group js-group-name-auth "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hiddenByDefault") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":39},"end":{"line":2,"column":76}}})) != null ? stack1 : "")
    + "'\n  data-required-data-message=\""
    + alias2(__default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"auth_component.mobile.name.message.required",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":30},"end":{"line":3,"column":84}}}))
    + "\"\n  data-invalid-data-message=\""
    + alias2(__default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"auth_component.mobile.name.message.invalid",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":29},"end":{"line":4,"column":82}}}))
    + "\" \n  data-type='name'\n>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"fields") : depth0)) != null ? lookupProperty(stack1,"withoutLabels") : stack1),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":2},"end":{"line":22,"column":13}}})) != null ? stack1 : "")
    + "\n  <div class='text-field-wrapper'>\n"
    + ((stack1 = container.invokePartial(require("../../desktop/_icons-holder.handlebars"),depth0,{"name":"../../desktop/_icons-holder","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <input \n      class='text-field js-input-name-auth'\n      style='"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customFormSettings") : depth0)) != null ? lookupProperty(stack1,"styles") : stack1)) != null ? lookupProperty(stack1,"inputStyles") : stack1), depth0))
    + "' \n      type='text' \n      name='name'\n      value='"
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fields") : depth0)) != null ? lookupProperty(stack1,"values") : stack1)) != null ? lookupProperty(stack1,"profile_attributes") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "' \n      maxlength='"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fields") : depth0)) != null ? lookupProperty(stack1,"maxlength") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "' \n      data-type='name'\n      placeholder=\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"fields") : depth0)) != null ? lookupProperty(stack1,"withoutPlaceholder") : stack1),{"name":"unless","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":19},"end":{"line":34,"column":226}}})) != null ? stack1 : "")
    + "\" \n      autocomplete='name'\n    />\n  </div>\n  <div class='error-block js-error-message'></div>\n</div>\n\n";
},"usePartial":true,"useData":true});