import {
  getOptionsByName,
  getCurrentGroup,
  createValidationError,
  optionalTypesToNames,
  toggleComponentLoadingIndicator,
  setValidationState,
  getElement,
} from './common-utils.js';

import {
  toggleCodeFields,
  needToHideCodeFields,
  toggleGenerateCodeLinks,
} from './code-field-utils.js';

import {toggleContent} from './content-types-utils.js';
import {toggleDisableState} from './form-state-utils.js';

const isEmailAndPhoneValid = (errors) => {
  return !errors.filter((error) => {
    return error.errorAttribute === 'email' || error.errorAttribute === 'phone';
  }).length;
};

const setFormState = (user, action, $currentComponent) => {
  getElement(
    optionalTypesToNames($currentComponent, 'visibleIfDataCorrect', 'group'),
    $currentComponent
  ).removeClass('dn');
  toggleCodeFields(
    $currentComponent,
    true,
    needToHideCodeFields($currentComponent)
  );
  toggleGenerateCodeLinks($currentComponent, user, action);
  toggleDisableState($currentComponent, ['nameField', !!user]);
  getElement(
    optionalTypesToNames($currentComponent, 'visibleForNewUsers', 'group'),
    $currentComponent
  ).toggleClass('dn', !!user);
  getElement('userField', $currentComponent).val(user ? user.id : '');
  toggleDisableState($currentComponent, ['userField', !user]);
  getOptionsByName('toggleContent', $currentComponent) &&
    toggleContent(user, $currentComponent);
};

const getErrorsAccordingToRule = (rule, $elementsToValidate) => {
  return $elementsToValidate
    .map((index, element) => {
      const $element = $(element),
        data = getCurrentGroup($element).data();
      let error;

      switch (rule) {
        case 'invalid':
          error =
            $.trim($element.val()) &&
            !data.valid &&
            createValidationError({
              message: data.invalidDataMessage,
              errorAttribute: data.type,
            });
          break;
        case 'required':
          error =
            !$.trim($element.val()) &&
            createValidationError({
              message: data.requiredDataMessage,
              errorAttribute: data.type,
            });
      }

      return error;
    })
    .get()
    .filter((error) => error);
};

const getValidationErrors = (validationRules, $currentComponent) => {
  return Object.keys(validationRules).reduce((errors, rule) => {
    return errors.concat(
      getErrorsAccordingToRule(
        rule,
        getElement(
          optionalTypesToNames($currentComponent, validationRules[rule]),
          $currentComponent
        )
      )
    );
  }, []);
};

export const validateComponent = (validationRules, $currentComponent) => {
  const isValid = $.Deferred(),
    errors = getValidationErrors(validationRules, $currentComponent);

  return $currentComponent.data('valid') && !errors.length
    ? isValid.resolve()
    : isValid.reject(errors);
};

export const setComponentState = (
  $currentComponent,
  user,
  doAdditionalStateChanges,
  action
) => {
  app.config.authComponent.currentProcessingUser = user;
  toggleComponentLoadingIndicator(false, $currentComponent);
  setValidationState($currentComponent, user);
  validateComponent(
    {
      invalid: 'toCheckFormat',
    },
    $currentComponent
  ).always((errors) => {
    if (!errors || isEmailAndPhoneValid(errors)) {
      setFormState(user, action, $currentComponent);
    }
  });
  doAdditionalStateChanges && doAdditionalStateChanges(user);
};
