/**
 * Модуль для инициализации авторизационного компонента в попапе.
 *
 * Это обёртка над авторизационным компонентом, предназначенная сделать показ авторизационного
 * компонента в попапе простым и универсальным процессом.
 *
 * Документация: https://github.com/abak-press/apress-clearance/blob/master/docs/components/dynamic_auth_component.md
 *
 */

import {getLoadedApplicationUtils} from '../../utils/common-utils.js';

app.modules.dynamicAuthComponent = (function (self) {
  const SELECTORS = {
      item: '.js-show-dynamic-auth-component',
    },
    CLASS_NAMES = {
      component: 'auth-component-${type}',
    };
  let _dynamicAuthComponentConfig;

  function _prepareClassName(type) {
    return CLASS_NAMES.component.replace('${type}', type);
  }

  function _prepareOptions(type) {
    const options = _dynamicAuthComponentConfig[type],
      containerName = options.containerName;

    return containerName
      ? $.extend(
          true,
          {},
          {popup: {}},
          app.modules.authComponentContainer
            .getComponentContainer(containerName)
            .getOptions(),
          options
        )
      : options;
  }

  function _getComponentType(type) {
    return type || 'sign-in';
  }

  function _renderComponent(type) {
    const $signInPopup = $('.' + _prepareClassName(type));

    getLoadedApplicationUtils().invariant(
      _dynamicAuthComponentConfig[type],
      'Need to set up config for auth component with %s type',
      type
    );

    if ($signInPopup.length) {
      return $signInPopup
        .show()
        .trigger('render:authComponent', [_prepareOptions(type)]);
    }

    $('<div>')
      .addClass(_prepareClassName(type))
      .appendTo('body')
      .trigger('render:authComponent', [_prepareOptions(type)]);
  }

  function _onShowDynamicAuthComponent(event, type) {
    _renderComponent(_getComponentType(type));
  }

  function _getDynamicAuthComponentConfig() {
    getLoadedApplicationUtils().invariant(
      getLoadedApplicationUtils().getFromObjectByPath(
        'authComponent.wrappers.dynamic',
        app.config
      ),
      'Need to set up app.config.authComponent.wrappers.dynamic config.'
    );

    getLoadedApplicationUtils().invariant(
      getLoadedApplicationUtils().isPlainObject(
        app.config.authComponent.wrappers.dynamic
      ),
      'The value of component config should be a plain object.'
    );

    return app.config.authComponent.wrappers.dynamic;
  }

  function _onClickItem(event) {
    event.preventDefault();

    _renderComponent(_getComponentType(event.target.dataset.authComponentType));
  }

  function _listener() {
    $doc
      .on('click', SELECTORS.item, _onClickItem)
      .on('show:dynamicAuthComponent', _onShowDynamicAuthComponent);
  }

  $.extend(self, {
    load: function () {
      _dynamicAuthComponentConfig = _getDynamicAuthComponentConfig();

      _listener();
    },
    // Для сброса состояния модуля при тестировании
    reset: function () {
      _dynamicAuthComponentConfig = null;
    },
    onClickItem: _onClickItem,
    onShowDynamicAuthComponent: _onShowDynamicAuthComponent,
  });

  return self;
})(app.modules.dynamicAuthComponent || {});
