var Handlebars = require("../../../../../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <div class='auth-component-blocked-tip'>\n      "
    + container.escapeExpression(__default(require("../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"auth_component.blockedAuth",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":4,"column":43}}}))
    + "\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='auth-component-section auth-component-section-footer'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"lockUpdates") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":6,"column":9}}})) != null ? stack1 : "")
    + "  <div class='auth-component-button-cell'>\n"
    + ((stack1 = container.invokePartial(require("./_button.handlebars"),depth0,{"name":"_button","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n  <div class='auth-component-tip'>\n"
    + ((stack1 = container.invokePartial(require("./_tip.handlebars"),depth0,{"name":"_tip","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n</div>\n";
},"usePartial":true,"useData":true});