export default {
  search: 'onSearch',
  mount: 'onMount',
  render: 'onRender',
  processUserData: 'onProcessUserData',
  processFieldData: 'onProcessFieldData',
  afterFieldDataProcessed: 'onAfterFieldDataProcessed',
  completed: 'onCompleted',
  error: 'onError',
};
