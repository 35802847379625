import {getProcessDescriptionData} from './process-description-data-utils.js';

import {
  getLoadedApplicationUtils,
  getOptionsByName,
  getElement,
} from './common-utils.js';

const getFieldValue = (fieldType, $currentComponent) =>
  getElement(fieldType + 'Field:not(:disabled)', $currentComponent).val();

const getOriginalComponentData = ($currentComponent) => {
  return {
    id: getFieldValue('user', $currentComponent),
    email: getFieldValue('email', $currentComponent),
    phone: getFieldValue('phone', $currentComponent),
    password: getFieldValue('password', $currentComponent),
    auth_code: getFieldValue('code', $currentComponent),
    profile_attributes: getFieldValue('name', $currentComponent) && {
      name: getFieldValue('name', $currentComponent),
    },
  };
};

export const getComponentData = ($currentComponent, data, toSignUp) => {
  data = data || {};

  return getLoadedApplicationUtils().filterObject(
    $.extend(
      true,
      {},
      data.withSID ? data : getOriginalComponentData($currentComponent),
      !data.withSID &&
        toSignUp &&
        getOptionsByName(
          'getAdditionalUserData',
          $currentComponent
        )(getProcessDescriptionData())
    ),
    function (obj, key) {
      return obj[key];
    }
  );
};
